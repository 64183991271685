import generateRender from 'shared/utils/generateRender';
import DefaultHeader from './DefaultHeader';
import HighlightedHeader from './HighlightedHeader';

const WatchAndEarnModalHeader = generateRender({
  default: DefaultHeader,
  millelacs: HighlightedHeader,
});

export default WatchAndEarnModalHeader;

