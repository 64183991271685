import React from 'react';
import styled, { css } from 'styled-components';
import theme from 'shared/assets/style/theme';
import useThemeContext from 'shared/context/hooks/useThemeContext';
function FormInput(props) {
  const { SignUp } = useThemeContext();
  return <Input theme={SignUp.FormInputTheme} {...props} />;
}

export default FormInput;

const errorBorder = css`
  border: 1px solid red;
`;

const Input = styled.input`
  width: 100%;
  height: 42px;
  text-align: left;
  padding-left: 1em;
  box-shadow: none;
  background-color: unset;
  color: white;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${theme.palette.common[1]};
  }
  ${({ theme }) => theme}
  ${({ error }) => error && errorBorder}
`;

