import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'shared/node_modules/react-redux';
import styled from 'styled-components';
import Ribbon from './Ribbon';
import generateEventsCardDataRendering from 'shared/screens/Events/utils/generateEventsCardDataRendering';
import GameImage from './GameImage';
import TimeRemainingText from '../../../components/TimeRemainingText';
import { counterTypes, normalizeTimestamp } from 'shared/utils/TimestampManager';
import { sendEventsEvent } from 'shared/screens/Events/analytics/eventsLogger';
import EventsCardDetail from './CardsDetail/CardsDetail';
import { EVENTS_CARD_TYPE, EVENTS_EVENT_TYPES, EVENTS_GAMEPLAY_STATUS } from 'shared/screens/Events/constants';
import { TEXT_KEY, getText } from 'shared/utils/localization';
import LiveOpsCard from './LiveOpsCard';
import ThemeContext from 'shared/context/ThemeContext';
import useScrollToCard from '../hooks/useScrollToCard';

const EventsCard = ({ object, eventsAssets, texts, defaultOpen = false, scrollBoxRef }) => {
  const { elementRef } = useScrollToCard({ scrollBoxRef, scrollOnMount: defaultOpen });
  const { eventGameplayStatus, endTimestamp, cardType, assetsPackageId, eventId } = object;
  const { endedLabel, completedLabel } = texts;
  const eventsAssetsByEvent = useSelector(state => state.assetsPackage.assets.EVENTS_FEATURE[assetsPackageId]);
  eventsAssets = Object.assign({}, eventsAssets, eventsAssetsByEvent);
  const renderElements = generateEventsCardDataRendering(object, eventsAssets);

  const { Events } = useContext(ThemeContext);

  const [openCardDetail, setOpenCardDetail] = useState(defaultOpen);

  const { BackgroundCard, GlowCard, BottomCard, Title, ShadowCard, PodiumBottom, PodiumLightBottom, StrokeColor } =
    renderElements;

  useEffect(() => {
    openCardDetail && sendEventsEvent(EVENTS_EVENT_TYPES.EVENTS_OPEN_EVENT_CARD, { eventId });
  }, [openCardDetail]);

  return (
    <Container ref={elementRef} openCardDetail={openCardDetail}>
      <Ribbon item={object} renderElements={renderElements} />
      <CardTouchable onClick={() => setOpenCardDetail(!openCardDetail)}>
        <Card bgImage={BackgroundCard}>
          <CardGlow bgImage={GlowCard}>
            <CardTitle src={Title} />
            <GameImage item={object} />
          </CardGlow>
          <Bottom bgImage={BottomCard}>
            <TimeLeftText>
              <TimeRemainingText
                prefix={getText(TEXT_KEY.TIME_REMAINING_PREFIX)}
                until={normalizeTimestamp(endTimestamp)}
                style={{ ...TimeLeftTextStyle, ...Events.TimeLeftText }}
                strokeColor={StrokeColor}
                counterType={counterTypes.EVENTS}
              />
            </TimeLeftText>
          </Bottom>
          <Shadow src={ShadowCard} />
          {cardType === EVENTS_CARD_TYPE.TOP_BANNER && (
            <LiveOpsCard renderElements={renderElements} liveOpsTexts={object.texts} />
          )}
        </Card>
        {eventGameplayStatus !== EVENTS_GAMEPLAY_STATUS.RUNNING && (
          <EndedText>{eventGameplayStatus === EVENTS_GAMEPLAY_STATUS.EXPIRED ? endedLabel : completedLabel}</EndedText>
        )}
      </CardTouchable>
      <PodiumContainer>
        <PodiumLight src={PodiumLightBottom} />
        <Podium src={PodiumBottom} />
      </PodiumContainer>
      <EventsCardDetail
        renderElements={renderElements}
        item={object}
        openCardDetail={openCardDetail}
        setOpenCardDetail={setOpenCardDetail}
        eventsAssets={eventsAssets}
      />
    </Container>
  );
};

export default EventsCard;

const Container = styled.div`
  height: 30rem;
  width: 16rem;
  margin-right: ${({ openCardDetail }) => (openCardDetail ? '70vh' : '0')};
  transition: 0.7s ease all;

  @media only screen and (max-height: 999px) {
    @media (max-width: 1900px) {
      width: 18rem;
    }

    @media (max-width: 1400px) {
      width: 22rem;
    }

    @media (min-width: 1901px) {
      width: 17rem;
    }
  }

  @media only screen and (max-height: 820px) {
    @media (min-width: 1601px) {
      width: 16rem;
    }

    @media (max-width: 1600px) {
      width: 18rem;
    }

    @media (max-width: 1400px) {
      width: 18rem;
    }
  }

  @media only screen and (min-height: 1000px) {
    @media (max-width: 1999px) {
      width: 22rem;
    }

    @media (max-width: 1200px) {
      width: 24rem;
    }

    @media (min-width: 2000px) {
      width: 20rem;
    }
  }

  @media only screen and (min-height: 1100px) {
    width: 26rem;
  }

  @media only screen and (min-height: 1200px) {
    width: 30rem;
  }
`;

const CardTouchable = styled.div`
  padding: 10px;
  width: 37vh;
  height: 55vh;
  z-index: 10;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
`;

const Card = styled.div`
  height: 100%;
  width: 100%;
  z-index: 1;
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CardGlow = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 8;
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-left: 0.2rem;
`;

const CardTitle = styled.img`
  position: relative;
  width: 75%;
  height: 15%;
  top: 10%;
  z-index: 2;
  align-self: center;
  display: ${({ src }) => (src ? 'block' : 'none')};
  object-fit: contain;
`;

const Bottom = styled.div`
  position: absolute;
  height: 8.5vh;
  width: 94%;
  z-index: 0;
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  bottom: 0.3rem;

  @media only screen and (min-height: 1080px) {
    width: 95%;
  }
  @media only screen and (max-height: 800px) {
    width: 93%;
  }

  @media only screen and (max-height: 650px) {
    width: 90%;
  }
`;

const TimeLeftText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TimeLeftTextStyle = {
  fontSize: '2.2vh',
  fontWeight: '700',
  marginLeft: '2rem',
  marginTop: '0.4rem',
};

const Shadow = styled.img`
  position: absolute;
  width: 97%;
  height: 98%;
  bottom: 0.3rem;
  left: 0.3rem;
  z-index: 10;
  display: ${({ src }) => (src ? 'block' : 'none')};
  @media only screen and (max-height: 800px) {
    width: 96%;
    height: 97.5%;
  }
`;

const EndedText = styled.p`
  font-size: 1.7rem;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 1px 1.5px 4px #424242;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
`;

const PodiumContainer = styled.div`
  height: 12vh;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: -9vh;
  width: 37vh;

  @media only screen and (max-height: 865px) and (min-width: 1500px),
    only screen and (min-height: 950px) and (max-height: 1000px) {
    bottom: -12vh;
  }
`;

const PodiumLight = styled.img`
  position: relative;
  left: 0%;
  z-index: 1;
  bottom: -2.5rem;
  width: 32vh;

  @media only screen and (max-height: 999px) {
    @media (max-width: 1900px) {
      width: 16vw;
    }

    @media (max-width: 1400px) {
      width: 20vw;
    }

    @media (min-width: 1901px) {
      width: 14vw;
    }

    @media (min-width: 2100px) {
      width: 11vw;
    }
  }

  @media only screen and (max-height: 820px) {
    @media (max-width: 1900px) {
      width: 12vw;
      bottom: -1.5rem;
    }

    @media (max-width: 1600px) {
      width: 14vw;
      bottom: -2rem;
    }

    @media (max-width: 1400px) {
      width: 15vw;
      bottom: -2rem;
    }

    @media (min-width: 1901px) {
      width: 8vw;
      bottom: -1.5rem;
    }
  }

  @media only screen and (min-height: 1000px) {
    @media (max-width: 1400px) {
      width: 22vw;
    }

    @media (max-width: 1200px) {
      width: 24vw;
    }

    @media (min-width: 1401px) {
      width: 16vw;
    }

    @media (min-width: 2000px) {
      width: 14vw;
    }
  }
`;

const Podium = styled.img`
  flex: 1;
  width: 20vw;
  height: 15rem;

  @media only screen and (max-height: 999px) {
    @media (max-width: 1900px) {
      width: 22vw;
    }

    @media (max-width: 1400px) {
      width: 30vw;
    }

    @media (min-width: 1901px) {
      width: 20vw;
    }

    @media (min-width: 2100px) {
      width: 16vw;
    }
  }

  @media only screen and (max-height: 820px) {
    @media (max-width: 1900px) {
      width: 17vw;
    }

    @media (max-width: 1600px) {
      width: 20vw;
    }

    @media (max-width: 1400px) {
      width: 22vw;
    }

    @media (min-width: 1901px) {
      width: 12vw;
    }
  }

  @media only screen and (min-height: 1000px) {
    @media (max-width: 1400px) {
      width: 30vw;
    }

    @media (max-width: 1200px) {
      width: 35vw;
    }

    @media (min-width: 1401px) {
      width: 23vw;
    }

    @media (min-width: 2000px) {
      width: 20vw;
    }
  }
`;

