import React from 'react';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { HeaderTitleWrapper, HeaderLightDivider } from '../styledComponents';

function DefaultLinkAccountHeader({ title }) {
  return (
    <>
      <HeaderTitleWrapper>
        <span data-label={'PURCHASE SUCCESSFUL'} className={`title ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}>
          {title}
        </span>
        <HeaderLightDivider alt="HeaderLightDivider" />
      </HeaderTitleWrapper>
    </>
  );
}

export default DefaultLinkAccountHeader;

