import React from 'react';
import {
  DialogHeaderContainer,
  HeaderWrapper,
  DialogHeaderHeight,
  HighlightedHeaderContainer,
} from '../styledComponents';

const HighlightedHeader = ({ title }) => {
  return (
    <>
      <HeaderWrapper>
        <DialogHeaderContainer>
          <HighlightedHeaderContainer>{title}</HighlightedHeaderContainer>
        </DialogHeaderContainer>
      </HeaderWrapper>
      <DialogHeaderHeight />
    </>
  );
};

export default HighlightedHeader;

