import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import AssetPreloader from './AssetPreloader';
import { connect } from '../../node_modules/react-redux';
import { setAssetReferenceCount as _setAssetReferenceCount, markAssetLoaded as _markAssetLoaded, setInitAppResponse as _setInitAppResponse, setInitAssetsLoaded as _setInitAssetsLoaded } from '../../state/actions/load';
import appInitDispatch from '../../lib/load/redux/appInitDispatch';
import { clearToken as _clearToken } from '../../state/actions/auth';
var mapStateToProps = function mapStateToProps(state) {
  return {
    load: state.load,
    token: state.token,
    assetsUrl: state.app.assetsUrls
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return _objectSpread(_objectSpread({}, appInitDispatch(dispatch)), {}, {
    setAssetReferenceCount: function setAssetReferenceCount(count) {
      dispatch(_setAssetReferenceCount(count));
    },
    markAssetLoaded: function markAssetLoaded(asset) {
      dispatch(_markAssetLoaded(asset));
    },
    setInitAssetsLoaded: function setInitAssetsLoaded(response) {
      dispatch(_setInitAssetsLoaded(response));
    },
    setInitAppResponse: function setInitAppResponse(response) {
      dispatch(_setInitAppResponse(response));
    },
    clearToken: function clearToken() {
      dispatch(_clearToken());
    }
  });
};
export default connect(mapStateToProps, mapDispatchToProps)(AssetPreloader);