import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/services/rtkQuery/axiosBaseQuery';
import { getTextsQuery } from './queries';
export var textsApi = createApi({
  reducerPath: 'textsQuery',
  baseQuery: axiosBaseQuery(),
  keepUnusedDataFor: 6000,
  tagTypes: ['Texts'],
  endpoints: function endpoints(builder) {
    return {
      getTexts: builder.query(getTextsQuery)
    };
  }
});
var useGetTextsQuery = textsApi.useGetTextsQuery,
  useLazyGetTextsQuery = textsApi.useLazyGetTextsQuery;
export { useGetTextsQuery, useLazyGetTextsQuery };
var getTexts = textsApi.endpoints.getTexts,
  textsReducerPath = textsApi.reducerPath,
  textsQueryReducer = textsApi.reducer,
  textsMiddleware = textsApi.middleware;
export { textsReducerPath, textsQueryReducer, textsMiddleware, getTexts };