import styled, { css } from 'styled-components';

export const HeaderTitleWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 3vmin;
  font-weight: 500;
  margin-bottom: 3vmin;
`;

export const HeaderLightDivider = styled.img.attrs({
  src: require(asset`images/Purchase_Flow_Assets/Light_Divider@3x.png`),
})`
  position: absolute;
  width: 100%;
  top: 100%;
  pointer-events: none;
  left: 0px;
  justify-content: center;
  align-items: center;
`;

export const headerContainerStyle = css`
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
`;

