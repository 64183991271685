import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/services/rtkQuery/axiosBaseQuery';
import { getActiveConsumables } from './queries';
export var shopApi = createApi({
  reducerPath: 'shopQuery',
  baseQuery: axiosBaseQuery(),
  keepUnusedDataFor: 0,
  tagTypes: ['Shop'],
  endpoints: function endpoints(builder) {
    return {
      getActiveConsumables: builder.query(getActiveConsumables)
    };
  }
});
var useGetActiveConsumablesQuery = shopApi.useGetActiveConsumablesQuery;
export { useGetActiveConsumablesQuery };
var getActiveConsumablesApi = shopApi.endpoints.getActiveConsumables,
  shopReducerPath = shopApi.reducerPath,
  shopQueryReducer = shopApi.reducer,
  shopMiddleware = shopApi.middleware;
export { shopReducerPath, shopQueryReducer, shopMiddleware, getActiveConsumablesApi };