import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { createSlice } from '@reduxjs/toolkit';
var sliceName = 'LAYOUT';
var initialState = {
  loadingLayer: false
};
var layoutSlice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    setPosition: function setPosition(state, action) {
      var _action$payload = action.payload,
        component = _action$payload.component,
        x = _action$payload.x,
        y = _action$payload.y,
        width = _action$payload.width,
        height = _action$payload.height;
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, component, {
        x: x,
        y: y,
        width: width,
        height: height
      }));
    },
    setDimensions: function setDimensions(state, action) {
      var _action$payload2 = action.payload,
        component = _action$payload2.component,
        width = _action$payload2.width,
        height = _action$payload2.height;
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, component, _objectSpread(_objectSpread({}, state[component]), {}, {
        width: width,
        height: height
      })));
    },
    setLoadingLayer: function setLoadingLayer(state, action) {
      state.loadingLayer = action.payload;
    }
  }
});
var actions = layoutSlice.actions,
  reducer = layoutSlice.reducer;
var setPosition = actions.setPosition,
  setDimensions = actions.setDimensions,
  setLoadingLayer = actions.setLoadingLayer;
export { setPosition, setDimensions, setLoadingLayer };
export default reducer;