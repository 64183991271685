import generateRender from 'shared/utils/generateRender';
import DefaultPurchaseHeader from './DefaultPurchaseHeader';
import HighlightedPurchaseHeader from '../../../../components/JSModal/components/HighlightedHeader';

const VipAccessHeader = generateRender({
  default: DefaultPurchaseHeader,
  millelacs: HighlightedPurchaseHeader,
});

export default VipAccessHeader;

