import React, { useEffect } from 'react';
import SAssetPreloader from 'shared/components/AssetPreloader';
import { ASSET_PRELOADER_ERROR, BOOLEANS } from 'shared/constants';
import { useDispatch } from 'shared/node_modules/react-redux';
import { markAssetLoaded } from 'shared/state/actions/load';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import Utils from 'shared/utils/index';
import preload from '../../lib/load/preload';

let images = [];
if (ENVIRONMENT_VARIABLES.PRELOAD_ASSETS === BOOLEANS.TRUE) {
  try {
    images = require(`../../manifests/image.manifest.${process.env['APPLICATION_TARGET']}.js`);
  } catch (e) {
    throw new Error(ASSET_PRELOADER_ERROR);
  }
}

const DefaultPreloader = () => {
  const dispatch = useDispatch();

  const preloadAssets = async () => {
    if (images.length === 0) return;

    const promises = images.map(image =>
      preload(image.file).then(result =>
        dispatch(
          markAssetLoaded({
            name: image.file,
            status: result,
          })
        )
      )
    );

    await Promise.all(promises);
  };

  useEffect(() => {
    preloadAssets();
  }, []);

  return (
    <SAssetPreloader
      images={images}
      render={({ percentLoaded }) => {
        return (
          <div
            className="AssetPreloader"
            style={{ backgroundImage: `url(${require(asset`images/AssetPreloader/loading_screen@3x.jpg`)})` }}
          >
            <div className={'AssetPreloaderLoadingBar' + (Utils.ieVersion() !== 0 ? ' ieHack' : '')}>
              <img
                alt=""
                className="ExpBarInset"
                src={require(asset`images/AssetPreloader/Loading_Bar_Inset@3x.png`)}
              />
              <img
                alt=""
                src={require(asset`images/AssetPreloader/Loading_Bar_Fill@3x.png`)}
                className="ExpBarInner"
                style={{ width: `${percentLoaded > 3 ? percentLoaded - 2 : 3}%` }}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

export default DefaultPreloader;

