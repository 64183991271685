import { ACTION_COMMANDS_FEATURE_GROUP, ACTION_COMMANDS_TYPE } from '../../../api/ActionCommandsInterface/constants';
import { EVENT_EMITTER_TYPE, FTUE_EVENT_TYPES } from '../../../constants';
import { sendFtueEvent } from '../../../lib/analytics/ftueLogger';
import Emitter from '../../../services/EmitterService';
export var skipTutorialGame = function skipTutorialGame(ftueStep, clearOnboarding, actionCommands) {
  var _actionCommands$paylo, _actionCommands$gameT, _actionCommands$gameT2;
  sendFtueEvent(FTUE_EVENT_TYPES.GAME_TUTORIAL_SKIP, {
    step: ftueStep,
    isFtueFlow: (actionCommands === null || actionCommands === void 0 || (_actionCommands$paylo = actionCommands.payload) === null || _actionCommands$paylo === void 0 ? void 0 : _actionCommands$paylo.featureGroup) === ACTION_COMMANDS_FEATURE_GROUP.FTUE
  });
  clearOnboarding();
  Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
    actionType: ACTION_COMMANDS_TYPE.NOTIFY_TRIGGER_PROCESSED_REQUEST,
    data: {
      triggerExecution: actionCommands === null || actionCommands === void 0 || (_actionCommands$gameT = actionCommands.gameTutorial) === null || _actionCommands$gameT === void 0 || (_actionCommands$gameT = _actionCommands$gameT.onSkipAction) === null || _actionCommands$gameT === void 0 ? void 0 : _actionCommands$gameT.triggerExecution,
      payload: actionCommands === null || actionCommands === void 0 || (_actionCommands$gameT2 = actionCommands.gameTutorial) === null || _actionCommands$gameT2 === void 0 || (_actionCommands$gameT2 = _actionCommands$gameT2.onSkipAction) === null || _actionCommands$gameT2 === void 0 ? void 0 : _actionCommands$gameT2.payload
    }
  });
  Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
    actionType: ACTION_COMMANDS_TYPE.CLEAR_ACTION_COMMAND
  });
};