import React, { Component } from 'react';
import SCurrencySelector from 'shared/screens/CurrencySelector';
import ImageBW from '../../components/ImageBoundsWrapper';
import Animated from 'animated/lib/targets/react-dom';
import Utils from 'shared/utils/index';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import Button from '../../components/Button/Button';
import { TEXT_KEY, getText } from 'shared/utils/localization';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import Tooltip from '../../components/Tooltip';
import { TooltipContainerStyle, PurchaseConfirmationTitle, ActionsContainer } from './styledComponents';

export default class CurrencySelector extends Component {
  state = {
    showPurchaseConfirmation: null,
    dimInnerModal: false,
  };

  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-currency-selector',
    };
  }

  casinoDollarPurchaseHandler = sharedCDollarPurchaseHandler => {
    this.setState({
      showPurchaseConfirmation: false,
      dimInnerModal: false,
    });

    sharedCDollarPurchaseHandler();
  };

  purchaseConfirmation = casinoDollarPurchaseHandler => {
    return (
      <Tooltip
        containerStyle={TooltipContainerStyle}
        visible={this.state.showPurchaseConfirmation}
        bottom
        style={{ width: '8rem', height: '7rem', top: '3.5rem', left: '3rem' }}
      >
        <PurchaseConfirmationTitle>{getText(TEXT_KEY.ARE_YOU_SURE)}</PurchaseConfirmationTitle>
        <ActionsContainer>
          <Button
            imageSource={require(asset`RoundedRect_Secondary.png`)}
            containerStyle={{
              width: '100%',
            }}
            imageStyle={{ height: 30 }}
            onClick={() => this.casinoDollarPurchaseHandler(casinoDollarPurchaseHandler)}
            label={getText(TEXT_KEY.YES)}
            textStyle={{ fontSize: '1.2vw' }}
            textStroke={BUTTON_STROKES.PRIMARY}
          />
          <Button
            imageSource={require(asset`RoundedRect_Danger.png`)}
            containerStyle={{
              width: '100%',
            }}
            imageStyle={{ height: 30 }}
            onClick={() => this.setState({ showPurchaseConfirmation: false, dimInnerModal: false })}
            label={'NO'}
            textStyle={{ fontSize: '1.2vw' }}
            textStroke={BUTTON_STROKES.PRIMARY}
          />
        </ActionsContainer>
      </Tooltip>
    );
  };

  render() {
    return (
      <SCurrencySelector
        navigation={this.props.navigation}
        render={args => {
          const {
            casinoDollarBalance,
            casinoDollarPurchaseDisabled,
            cdPurchaseDisabledMessage,
            purchaseHandler,
            casinoDollarPurchaseHandler,
            purchaseComplete,
          } = args;

          const BuyNowButtonSrc = casinoDollarPurchaseDisabled
            ? require(asset`RoundedRect_Disabled@3x.png`)
            : require(asset`RoundedRect_Primary@3x.png`);

          return (
            <>
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',

                  fontSize: '3vmin',
                  fontWeight: '500',
                  marginBottom: '3vmin',
                }}
              >
                <span
                  data-label={purchaseComplete ? 'PURCHASE COMPLETE!' : 'CHOOSE PAYMENT METHOD'}
                  className={`gradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                  style={{ lineHeight: 1 }}
                >
                  {purchaseComplete ? 'PURCHASE COMPLETE!' : 'CHOOSE PAYMENT METHOD'}
                </span>
                <img
                  src={require(asset`images/Purchase_Flow_Assets/Light_Divider@3x.png`)}
                  alt=""
                  style={{
                    position: 'absolute',
                    width: '100%',
                    top: '100%',
                    left: 0,
                  }}
                />
              </div>
              {!purchaseComplete && (
                <div className={'currencySelectorContainer'}>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      height: '95%',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      textAlign: 'center',
                      fontWeight: '300',
                    }}
                  >
                    {/* Casino Dollar */}
                    <ImageBW
                      as={Animated.div}
                      source={require(asset`section.png`)}
                      ibw={require(asset`section.ibw`)}
                      className={'paymentContainer'}
                    >
                      <span
                        data-label={'CASINO DOLLARS'}
                        className={`title ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                      >
                        CASINO DOLLARS
                      </span>
                      <img
                        src={require(asset`icon_casinodollars.png`)}
                        style={{
                          width: '50%',
                          objectFit: 'contain',
                        }}
                        alt=""
                      />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <span>Your Balance:</span>
                        <span style={{ fontSize: '2.5vmin', fontWeight: 600 }}>${casinoDollarBalance}</span>
                      </div>
                      {cdPurchaseDisabledMessage && (
                        <div className="casinoDollarsUnavailable">
                          <span>{cdPurchaseDisabledMessage}</span>
                        </div>
                      )}
                      <Button
                        imageSource={BuyNowButtonSrc}
                        containerStyle={{
                          padding: '2rem',
                          width: '100%',
                        }}
                        imageStyle={{
                          width: '75%',
                          cursor: casinoDollarPurchaseDisabled ? 'default' : 'pointer',
                          height: Utils.ieVersion() !== 0 ? '17%' : '',
                        }}
                        disabled={casinoDollarPurchaseDisabled}
                        onClick={
                          !casinoDollarPurchaseDisabled
                            ? () =>
                                this.setState({
                                  showPurchaseConfirmation: !this.state.showPurchaseConfirmation,
                                  dimInnerModal: !this.state.dimInnerModal,
                                })
                            : () => {}
                        }
                        label={getText(TEXT_KEY.BUY_NOW)}
                        textStyle={{ fontSize: '1.2vw' }}
                        textStroke={BUTTON_STROKES.PRIMARY}
                      />
                      {this.purchaseConfirmation(casinoDollarPurchaseHandler)}
                    </ImageBW>
                    {/* Credit Card */}
                    <ImageBW
                      as={Animated.div}
                      source={require(asset`section.png`)}
                      ibw={require(asset`section.ibw`)}
                      className={'paymentContainer'}
                    >
                      <span data-label={'CREDIT CARD'} className={`title ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}>
                        CREDIT CARD
                      </span>
                      <img
                        src={require(asset`images/Purchase_Flow_Assets/Credit_Card_Logos@3x.png`)}
                        style={{
                          width: '50%',
                          objectFit: 'contain',
                          marginBottom: '12%',
                          height: '50%',
                        }}
                        alt=""
                      />
                      <Button
                        imageSource={require(asset`RoundedRect_Primary@3x.png`)}
                        containerStyle={{
                          padding: '1.5rem',
                          width: '100%',
                        }}
                        imageStyle={{
                          width: '75%',
                          cursor: 'pointer',
                          height: Utils.ieVersion() !== 0 ? '17%' : '',
                        }}
                        onClick={purchaseHandler}
                        label={getText(TEXT_KEY.BUY_NOW)}
                        textStyle={{ fontSize: '1.2vw' }}
                        textStroke={BUTTON_STROKES.PRIMARY}
                      />
                    </ImageBW>
                  </div>
                </div>
              )}
            </>
          );
        }}
      />
    );
  }
}

