import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/services/rtkQuery/axiosBaseQuery';
import { postOTPSendEmailMutation, postOTPAuthenticateMutation } from './queries';
export var oneTimePasswordApi = createApi({
  reducerPath: 'oneTimePasswordQuery',
  baseQuery: axiosBaseQuery(),
  keepUnusedDataFor: 5,
  tagTypes: ['OneTimePassword'],
  endpoints: function endpoints(builder) {
    return {
      postOTPSendEmail: builder.mutation(postOTPSendEmailMutation),
      postOTPAuthenticate: builder.mutation(postOTPAuthenticateMutation)
    };
  }
});
var usePostOTPSendEmailMutation = oneTimePasswordApi.usePostOTPSendEmailMutation,
  usePostOTPAuthenticateMutation = oneTimePasswordApi.usePostOTPAuthenticateMutation;
export { usePostOTPSendEmailMutation, usePostOTPAuthenticateMutation };
var _oneTimePasswordApi$e = oneTimePasswordApi.endpoints,
  postOTPSendEmail = _oneTimePasswordApi$e.postOTPSendEmail,
  postOTPAuthenticate = _oneTimePasswordApi$e.postOTPAuthenticate,
  oneTimePasswordReducerPath = oneTimePasswordApi.reducerPath,
  oneTimePasswordQueryReducer = oneTimePasswordApi.reducer,
  oneTimePasswordMiddleware = oneTimePasswordApi.middleware;
export { oneTimePasswordReducerPath, oneTimePasswordQueryReducer, oneTimePasswordMiddleware, postOTPSendEmail, postOTPAuthenticate };