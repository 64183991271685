import React, { Component } from 'react';
import SCurrencyDisplay from 'shared/components/CurrencyDisplay';
import ImageBW from '../ImageBoundsWrapper';
import Tooltip from '../Tooltip';
import { TYPEOF } from 'shared/constants';
import { CurrencyImageWrapper, CoinsGlowAnimation } from './styledComponents';
import Bar from '../Bar/Bar';
import ThemeContext from 'shared/context/ThemeContext';

export const currencyDisplayComponentName = 'CurrencyDisplay';

export default class CurrencyDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      shouldAnimate: false,
    };
    this.componentRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value && this.state.shouldAnimate) {
      this.setState({ value: this.props.value });
    } else if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  componentDidMount() {
    this.getElementPosition();
    this.setState({ shouldAnimate: true });
    window.addEventListener('resize', this.getElementPosition);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getElementPosition);
  }

  _hoverHandler = event => {
    this.props.changeTooltipType(this.props.currencyType);
  };

  _toggleTooltip = event => {
    this.props.changeTooltipType(this.props.tooltipType === this.props.currencyType ? null : this.props.currencyType);
  };

  getElementPosition() {
    if (this.componentRef) {
      const element = this.componentRef.current;
      const { x, y, width, height } = element.getBoundingClientRect();
      const innerWidth = window.innerWidth;

      x < innerWidth / 2 && this.props.setPosition({ component: currencyDisplayComponentName, x, y, width, height });
      this.props.setDimensions({ component: currencyDisplayComponentName, width, height });
    }
  }

  render() {
    const themeContext = this.context;
    return (
      <SCurrencyDisplay
        {...this.props}
        navigation={this.props.navigation}
        value={this.state.value}
        imageOnly={this.props.imageOnly}
        onClick={this.props.onClick}
        toggleTooltip={this._toggleTooltip}
        render={args => {
          const { displayText, rawValue, truncated, onClickHandler, containerHeight, containerWidth } = args;

          const needHover = truncated || this.props.imageOnly;

          return (
            <div
              id={'CurrencyDisplay'}
              className={`CurrencyDisplayWrapper ${this.props.additionalWrapperClassNames}`}
              style={{
                ...styles.containerStyle,
                ...this.props.style,
                ...(this.props.onClick ? { cursor: 'pointer' } : {}),
              }}
              onMouseEnter={needHover ? this._hoverHandler : null}
              onClick={
                typeof onClickHandler === TYPEOF.FUNCTION
                  ? () => (!needHover ? onClickHandler(this.props.currencyType) : null)
                  : onClickHandler
              }
            >
              <CurrencyImageWrapper
                id="CurrencyBar"
                ref={this.componentRef}
                className="CurrencyIconWrapper"
                imageOnly={this.props.imageOnly}
              >
                {!this.props.imageOnly ? (
                  <Bar
                    barIcon={this.props.source}
                    label={displayText}
                    labelStyle={{
                      ...(this.props.iconType === 'coins'
                        ? themeContext.CurrencyDisplay.CoinsLabel
                        : themeContext.CurrencyDisplay.LoyaltyLabel),
                    }}
                    iconStyle={themeContext.CurrencyDisplay.Icons[this.props.iconType]}
                    animation={
                      !this.props.isCoinsGlowAnimationStopped &&
                      this.props.isCoinsGlowAnimationEnabled &&
                      CoinsGlowAnimation
                    }
                  />
                ) : this.props.ibw ? (
                  <ImageBW
                    source={this.props.source}
                    ibw={this.props.ibw}
                    manualAssetScale={this.props.manualAssetScale || 3}
                    style={{
                      ...styles.currencyImageBWStyle,
                      ...this.props.imgStyle,
                    }}
                  />
                ) : (
                  <img
                    src={this.props.source}
                    style={{
                      ...styles.currencyImageStyle,
                      ...this.props.imgStyle,
                    }}
                    alt=""
                  />
                )}
                <Tooltip
                  source={this.props.source}
                  ibw={this.props.ibw}
                  displayText={rawValue}
                  iconType={this.props.iconType}
                  visible={this.props.tooltipType && this.props.tooltipType === this.props.currencyType}
                  actionHandler={this.props.onClick}
                  style={{
                    height: containerHeight,
                    width: containerWidth,
                    left: this.props.imageOnly ? (50 - containerWidth) / 2 : 'unset',
                  }}
                />
              </CurrencyImageWrapper>
            </div>
          );
        }}
      />
    );
  }
}

CurrencyDisplay.contextType = ThemeContext;

const styles = {
  containerStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '1rem',
    maxWidth: 230,
  },
  currencyImageStyle: {
    position: 'absolute',
    left: 0,
    top: -2,
    zIndex: 1,
    height: 39,
    width: 32,
    objectFit: 'contain',
  },
  currencyImageBWStyle: {
    position: 'absolute',
    left: 0,
    top: -2,
    zIndex: 1,
    height: 36,
    width: 36,
    objectFit: 'contain',
  },
  currencyBarImage: {
    height: '100%',
    width: '100%',
  },
};

