import styled from 'styled-components';

const headerBackground = require(asset`Full_Modal_Header@3x.png`);

export const HeaderContainer = styled.div`
  position: absolute;
  top: -1px;
  left: 0px;
  height: 4rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  border-top-right-radius: 35px;
  border-top-left-radius: 35px;
  background-size: calc(100% + 1px) 100%;
  background-image: url(${headerBackground});
`;

export const DialogHeaderContainer = styled(HeaderContainer)`
  top: -27px;
  height: 3.7rem;
  left: -22px;
  width: calc(100% + 44px);
  background-size: calc(100% + 2px) 100%;
`;

export const DialogHeaderHeight = styled.div`
  height: 3.7rem;
`;

export const HeaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
`;

export const HeaderTextWrapper = styled.span`
  text-align: center;
  display: flex;
  justify-content: center;
  flex: 1;
  margin-top: 3vmin;
  height: 6vmin;
  font-weight: 600;
  font-size: calc(18px + (30 - 18) * ((100vw - 800px) / (1600 - 800)));
`;

export const HeaderHeight = styled(DialogHeaderHeight)`
  margin-bottom: 3.5rem;
`;

export const DefaultHeaderContainer = styled.div`
  height: 30%;
  width: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const HighlightedHeaderContainer = styled.div`
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: -5px;
`;

