import React from 'react';
import CustomScrollBar from '../../../../../components/CustomScrollBar';
import { HistoryTable, HistoryTableHeaderContainer, HistoryTableBody, customScrollBarProps } from './styledComponents';
import HistoryItem from './HistoryItem';
import useWindowSize from '../../../../../hooks/useWindowSize';
import HistoryListHeader from './HistoryListHeader';

const HistoryList = ({ history, setActiveHistoryItem, rewardCenterAssets }) => {
  const { height } = useWindowSize();
  const displayDetailsColumn = history.some(item => item.showDetailsButton === true);

  return (
    <>
      <HistoryTable>
        <HistoryTableHeaderContainer background={rewardCenterAssets.REWARD_CENTER_MENU_HISTORY_BANNER}>
          <HistoryListHeader displayDetailsColumn={displayDetailsColumn} />
        </HistoryTableHeaderContainer>
        <HistoryTableBody>
          <CustomScrollBar
            {...customScrollBarProps}
            customHeight={height > 1000 ? '55vh' : height > 850 && height <= 900 ? '45vh' : '50vh'}
          >
            {history.map((item, idx) => (
              <HistoryItem
                key={`HistoryItem-${item.rpid}`}
                item={item}
                displayDetailsColumn={displayDetailsColumn}
                setActiveHistoryItem={setActiveHistoryItem}
                index={idx}
              />
            ))}
          </CustomScrollBar>
        </HistoryTableBody>
      </HistoryTable>
    </>
  );
};

export default HistoryList;

