import { Form } from 'react-bootstrap';
import theme from 'shared/assets/style/theme';
import styled from 'styled-components';
import Button from '../../components/Button/Button';

const ButtonSrc = {
  ROUNDED_GREY: require(asset`RoundedRect_Disabled@2x.png`),
  ROUNDED_PINK: require(asset`RoundedRect_Primary@2x.png`),
};

const Container = styled.div`
  width: 100%;
  max-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormBody = styled.div`
  text-align: left;
  display: flex;
  width: 100%;
`;

const FormInput = styled(Form.Group)`
  flex: 1;
  margin: 10px;
  margin-top: 30px;
`;

const ErrorText = styled(Form.Text)`
  background-color: ${theme.palette.secondary[6]};
  color: ${theme.palette.common[1]};
  font-size: 12px;
  width: 100%;
  height: 20px;
  padding-left: 4px;
  padding-right: 8px;
  text-align: left;
  margin: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  &.success {
    background-color: ${theme.palette.success[1]};
    color: ${theme.palette.common[0]};
  }
`;

const Password = styled.div`
  flex: 1;
`;

const ForgotPassword = styled.div`
  margin-left: 10px;
  text-decoration: underline;
  font-size: 14px;
  color: ${theme.palette.common[34]};
  cursor: pointer;
`;

const Status = styled.div`
  color: red;
  margin-top: 1em;
`;

const LoginButton = styled(Button).attrs(({ isValid }) => ({
  imageSource: isValid ? ButtonSrc.ROUNDED_PINK : ButtonSrc.ROUNDED_GREY,
}))`
  cursor: pointer;
  font-size: 1.7rem;
  font-weight: 600;
  width: 181px;
  height: 59px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
`;

const Peeker = {
  position: 'absolute',
  top: '13%',
  right: 16,
  zIndex: 2,
  cursor: 'pointer',
};

export { Container, FormBody, FormInput, ErrorText, Password, ForgotPassword, Status, LoginButton, Peeker };

