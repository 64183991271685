import { FTUE_STEPS_KEYS } from 'shared/constants';
import styled from 'styled-components';
import generateRender from 'shared/utils/generateRender';

const viewportWidth = window.innerWidth;
const vwInPixels = viewportWidth / 100;
const GAME_TILE_WIDTH = 16 * vwInPixels;

const scrollBarTrackBackgroundColor = generateRender({
  default: 'rgb(68,9,110)',
  ti: 'rgb(84,112,173)',
  admiral: 'rgb(12,39,87)',
  admes: 'rgb(12,39,87)',
  millelacs: '#00325F',
});

const scrollBarTrackBottomPosition = generateRender({
  default: '22px',
  ti: '30px',
  admiral: '58px',
  admes: '58px',
});

const scrollBarArrowBottomPosition = generateRender({
  default: '11px',
  ti: '16px',
  admiral: '44px',
  admes: '44px',
});

const getScrollBarConfig = (ftueStep, gameIndex, hasPromotions, totalGamesCount) => {
  return {
    isDraggableX: true,
    showVertical: false,
    showHorizontal: totalGamesCount > 8,
    showHorizontalArrows: totalGamesCount > 8,
    showByDefault: true,
    scrollToPosition: ftueStep === FTUE_STEPS_KEYS.HIGHLIGHT_A_GAME ? 0 : gameIndex * GAME_TILE_WIDTH,
    customStyle: { display: 'flex' },
    customHeight: '25vh',
    customWidth: hasPromotions ? '62vw' : '87vw',
    customScrollBarXHeight: 16,
    customHorizontalTrackStyle: {
      width: '90%',
      left: '5%',
      backgroundColor: scrollBarTrackBackgroundColor,
      boxShadow: 'inset 0 0 4px 2px rgba(0,0,0,0.5)',
      bottom: scrollBarTrackBottomPosition,
    },
    customHorizontalThumbStyle: {
      backgroundColor: 'white',
      boxShadow: 'inset -4px 0 4px -3px rgba(0, 0, 0, 0.5), inset 4px 0 4px -3px rgba(0, 0, 0, 0.5)',
    },
    customArrowStyle: {
      bottom: scrollBarArrowBottomPosition,
    },
  };
};

const MainContainer = styled.div`
  display: flex;
  padding-bottom: 2rem;

  @media only screen and (max-height: 780px) {
    padding-bottom: 3.5rem;
  }
`;

export { getScrollBarConfig, MainContainer };

