import React from 'react';
import styled from 'styled-components';

const RewardCenterCategoriesClosed = ({ rewardCenterAssets }) => {
  return (
    <>
      <CategoriesContainer bgImage={rewardCenterAssets.REWARD_CENTER_CLOSED_MENU_BACKGROUND}>
        <Title src={rewardCenterAssets.REWARD_CENTER_CLOSED_MENU_TITLE} />
        <ArrowLeft src={rewardCenterAssets.REWARD_CENTER_CLOSED_MENU_ARROW} />
        <Subtitle src={rewardCenterAssets.REWARD_CENTER_CLOSED_MENU_CATEGORIES} />
        <ArrowRight src={rewardCenterAssets.REWARD_CENTER_CLOSED_MENU_ARROW} />
      </CategoriesContainer>
    </>
  );
};

export default RewardCenterCategoriesClosed;

const CategoriesContainer = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.img`
  position: absolute;
  top: -1rem;
  height: 40%;
  object-fit: contain;
  left: 110px;
  width: 60%;
`;

const Subtitle = styled.img`
  width: 30%;
  position: relative;
  height: 15%;
  object-fit: contain;
`;

const ArrowLeft = styled.img`
  position: relative;
  height: 30%;
  left: 40px;
`;

const ArrowRight = styled.img`
  position: relative;
  height: 30%;
  left: -40px;
`;

