import React, { useState } from 'react';
import RewardCenterCardContainer from './RewardCenterCard';
import generateRewardCenterCardDataRendering from 'shared/screens/RewardCenter/utils/generateRewardCenterCardDataRendering';
import styled from 'styled-components';
import { numberWithCommas } from 'shared/utils/number';
import { getOfferDescription, hasSoldOutState } from 'shared/screens/RewardCenter/utils/validations';

const RewardCenterOffers = ({ object, rewardCenterAssets, RewardsImageBaseURL, setSelectedOffer }) => {
  const { Frame, Banner, Shadow, Loyalty, Coins } = generateRewardCenterCardDataRendering(object, rewardCenterAssets);
  const { loyaltyPoints, offerImage, ribbontype } = object;

  const [offerImageLoaded, setOfferImageLoaded] = useState(false);

  return (
    <RewardCenterCardContainer
      handleClick={() => (!hasSoldOutState(ribbontype) ? setSelectedOffer(object) : null)}
      Frame={Frame}
    >
      {Banner && <CardRibbon src={Banner} />}
      {Shadow && <CardShadow src={Shadow} />}
      <CardContent hasRibbon={Banner}>
        {offerImage && RewardsImageBaseURL && (
          <CardImage src={`${RewardsImageBaseURL}${offerImage}`} onLoad={() => setOfferImageLoaded(true)} />
        )}
        {offerImageLoaded && (
          <DescriptionWrapper>
            <>
              <LoyaltyWrapper>
                <LoyaltyIcon src={Loyalty} />
                <LoyaltyText hasSoldOutState={hasSoldOutState(ribbontype)}>
                  {numberWithCommas(loyaltyPoints)}
                </LoyaltyText>
              </LoyaltyWrapper>
              <CoinsWrapper>
                <CoinsIcon src={Coins} />
                <CoinsValue hasSoldOutState={hasSoldOutState(ribbontype)}>{getOfferDescription(object)}</CoinsValue>
              </CoinsWrapper>
            </>
          </DescriptionWrapper>
        )}
      </CardContent>
    </RewardCenterCardContainer>
  );
};

export default RewardCenterOffers;

const CardImage = styled.img`
  width: 100%;
  object-fit: cover;
  position: relative;
  z-index: 1;
  border-top-left-radius: 10%;
  border-top-right-radius: 10%;
  height: 84%;
`;

const CardShadow = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
  z-index: 3;
  opacity: 0.5;
  left: 0rem;
`;

const CardRibbon = styled.img`
  width: 75%;
  bottom: 35rem;
  z-index: 5;
  position: absolute;
  top: -2rem;
  left: 2.3rem;
  @media only screen and (max-height: 800px) {
    top: -1.8rem;
  }
  @media only screen and (min-height: 950px) {
    top: -2.4rem;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 90%;
`;

const DescriptionWrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  height: 100%;
`;

const LoyaltyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const LoyaltyIcon = styled.img`
  height: 100%;
  width: 15%;
  object-fit: contain;
`;

const LoyaltyText = styled.p`
  font-size: 1.8rem;
  margin-bottom: 0;
  margin-right: 20px;
  background: ${({ hasSoldOutState }) =>
    hasSoldOutState ? '#BCBBBA' : 'linear-gradient(to bottom, #ffbd31 35%, #a45d00 55%, #d78900 60%, #ffdf98 80%)'};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  text-stroke: 0.5px #000;
  -webkit-text-stroke: 0.5px #000;
  text-shadow: 0px 5px 5px rgba(59, 59, 59, 0.6);
  @media only screen and (max-height: 800px) {
    font-size: 1.5rem;
  }
`;

const CoinsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const CoinsIcon = styled.img`
  height: 1.2rem;
  width: 10%;
  margin-right: 3px;
  object-fit: contain;
`;

const CoinsValue = styled.p`
  font-size: 1.1rem;
  color: ${({ hasSoldOutState }) => (hasSoldOutState ? '#BCBBBA' : '#fff')};
  text-shadow: 0px -1.5px 1px #000;
  font-weight: 600;
  margin-bottom: 0;
  @media only screen and (max-height: 900px) {
    font-size: 1rem;
  }
  @media only screen and (min-height: 950px) {
    font-size: 1.2rem;
  }
`;

