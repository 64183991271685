import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/services/rtkQuery/axiosBaseQuery';
import { postToggleMarketingEmailsMutation, postToggleNotificationsMutation, postTriggersNotifyMutation, deleteTriggersNotifyMutation } from './queries';
export var actionCommandsApi = createApi({
  reducerPath: 'actionCommandsQuery',
  baseQuery: axiosBaseQuery(),
  keepUnusedDataFor: 5,
  tagTypes: ['ActionCommands'],
  endpoints: function endpoints(builder) {
    return {
      postToggleMarketingEmails: builder.mutation(postToggleMarketingEmailsMutation),
      postToggleNotifications: builder.mutation(postToggleNotificationsMutation),
      postTriggersNotify: builder.mutation(postTriggersNotifyMutation),
      deleteTriggerNotify: builder.mutation(deleteTriggersNotifyMutation)
    };
  }
});
var usePostToggleMarketingEmailsMutation = actionCommandsApi.usePostToggleMarketingEmailsMutation,
  usePostToggleNotificationsMutation = actionCommandsApi.usePostToggleNotificationsMutation,
  usePostTriggersNotifyMutation = actionCommandsApi.usePostTriggersNotifyMutation,
  useDeleteTriggersNotifyMutation = actionCommandsApi.useDeleteTriggersNotifyMutation;
export { usePostToggleMarketingEmailsMutation, usePostToggleNotificationsMutation, usePostTriggersNotifyMutation, useDeleteTriggersNotifyMutation };
var _actionCommandsApi$en = actionCommandsApi.endpoints,
  postToggleMarketingEmails = _actionCommandsApi$en.postToggleMarketingEmails,
  postToggleNotifications = _actionCommandsApi$en.postToggleNotifications,
  deleteTriggerNotify = _actionCommandsApi$en.deleteTriggerNotify,
  postTriggersNotify = _actionCommandsApi$en.postTriggersNotify,
  actionCommandsReducerPath = actionCommandsApi.reducerPath,
  actionCommandsQueryReducer = actionCommandsApi.reducer,
  actionCommandsMiddleware = actionCommandsApi.middleware;
export { actionCommandsReducerPath, actionCommandsQueryReducer, actionCommandsMiddleware, postToggleMarketingEmails, postToggleNotifications, deleteTriggerNotify, postTriggersNotify };