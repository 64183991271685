import BottomBar from './BottomBar';
import { connect } from '../../node_modules/react-redux';
import { setModal as _setModal } from '../../state/actions/modal';
import { setSidebarState as _setSidebarState } from '../../state/actions/sidebar_state';
import { updateUnreadMessageCount as _updateUnreadMessageCount } from '../../state/actions/notification';
import { setOnboardingStep as _setOnboardingStep } from '../../state/actions/currentOnboarding';
import { setTimedBonus as _setTimedBonus } from '../../state/slices/consumables/consumables';
import { getMissions as _getMissions } from '../../state/query/missions';
import { getEvents } from '../../state/query/events';
var mapStateToProps = function mapStateToProps(state) {
  var _state$assetsPackage$;
  var vipGames = state.vipGames;
  var canEnterVipRoom = !!vipGames && !!vipGames.gameList && vipGames.gameList.length > 0 && vipGames.gameList[0].can_enter_room || null;
  return {
    navState: state.navState.navState,
    sidebarState: state.sidebarState.sidebarState,
    isVipRoomUnlocked: state.consumables.isVipRoomUnlocked,
    timedBonus: state.consumables.timedBonus,
    canEnterVipRoom: canEnterVipRoom,
    videoAds: state.videoAds,
    appBackground: state.app.appBackground,
    onboardingsSeen: state.ftue.onboardingsSeen[state.playerInfo.playerId] || [],
    unreadMessageCount: state.notifications.unreadMessagesCount,
    unreadOfferCount: state.rewardCenter.unreadOfferCount,
    missionsFeature: state.missions.missionsFeature,
    currentMission: state.missions.currentMission,
    missionsAssetsPackage: (_state$assetsPackage$ = state.assetsPackage.assets) === null || _state$assetsPackage$ === void 0 ? void 0 : _state$assetsPackage$.MISSIONS_FEATURE,
    playerId: state.playerInfo.playerId,
    events: getEvents.select({
      playerId: state.playerInfo.playerId,
      requestAssetsData: true
    })(state),
    onboardingStep: state.currentOnboarding.currentStep,
    isAccountLinked: state.playerInfo.isAccountLinked
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    setModal: function setModal(newModal) {
      dispatch(_setModal(newModal));
    },
    setSidebarState: function setSidebarState(newState) {
      dispatch(_setSidebarState(newState));
    },
    setOnboardingStep: function setOnboardingStep(step) {
      dispatch(_setOnboardingStep(step));
    },
    updateUnreadMessageCount: function updateUnreadMessageCount(messageCount) {
      dispatch(_updateUnreadMessageCount(messageCount));
    },
    setTimedBonus: function setTimedBonus(value) {
      dispatch(_setTimedBonus(value));
    },
    getMissions: function getMissions(args, params) {
      dispatch(_getMissions.initiate(args, params));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BottomBar);