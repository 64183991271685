import React, { Component } from 'react';
import SGameUnavailable from 'shared/screens/Game/GameUnavailable';
import theme from 'shared/assets/style/theme';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import ThemeContext from 'shared/context/ThemeContext';
import Button from '../../components/Button/Button';
import ModalHeader from './components/GameUnavaolableHeader';

export default class GameUnavailable extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-gameUnavailable',
    };
  }

  render() {
    const themeContext = this.context;
    return (
      <SGameUnavailable
        navigation={this.props.navigation}
        dismissModal={this.props.dismissModal}
        render={args => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                height: '100%',
              }}
            >
              <ModalHeader />
              <span
                style={{
                  fontSize: '1.2em',
                  marginTop: -30,
                  display: 'block',
                  width: '90%',
                  textAlign: 'center',
                  textShadow: `${theme.palette.primary[16]} 1px 1px 4px`,
                }}
              >
                {getText(TEXT_KEY.GAME_UNAVAILABLE)},
              </span>
              <span
                style={{
                  fontSize: '1.2em',
                  width: '90%',
                  textAlign: 'center',
                  textShadow: `${theme.palette.primary[16]} 1px 1px 4px`,
                }}
              >
                {getText(TEXT_KEY.TRY_AGAIN_LATER)}
              </span>
              <Button
                imageSource={require(asset`RoundedRect_Primary@3x.png`)}
                containerStyle={{
                  height: '25%',
                  marginTop: '1.1em',
                  width: '100%',
                  textAlign: 'center',
                  marginBottom: '1em',
                }}
                textStyle={themeContext.GameUnavailable.ButtonText}
                imageStyle={{ width: '23%', height: '100%' }}
                onClick={this.props.dismissModal}
                label={getText(TEXT_KEY.OK)}
              />
            </div>
          );
        }}
      />
    );
  }
}

GameUnavailable.hideCloseButton = true;
GameUnavailable.contextType = ThemeContext;

