import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';

import AutoForm from '../../components/Form';
import ImageBW from '../../components/ImageBoundsWrapper';
import SUpgradeAccountFlow from 'shared/screens/UpgradeAccountFlow';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';

import QueryParamsProxy from 'shared/utils/queryParamProxy';
import RouteParamConstants from 'shared/utils/routeParamConstants';
import SSOLinksInterface from 'shared/api/SSOLinksInterface';
import { SSOLinksResponseKeys } from 'shared/api/Constants';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { AUTH_TYPES } from 'shared/utils/authFlow/constants';
import ThemeContext from 'shared/context/ThemeContext';

export default class UpgradeAccountFlow extends Component {
  state = {};

  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-upgrade',
    };
  }

  static get DismissHandler() {
    QueryParamsProxy.setQueryParams({
      [RouteParamConstants.PARAM_MODAL]: undefined,
      [RouteParamConstants.PARAM_NAVIGATE_TO]: undefined,
      [RouteParamConstants.PARAM_UPGRADE_TYPE]: undefined,
      [RouteParamConstants.PARAM_PURCHASE_SKU]: undefined,
      [RouteParamConstants.PARAM_PURCHASE_TYPE]: undefined,
    });
  }

  _cancelHandler = () => {
    QueryParamsProxy.setQueryParams({
      [RouteParamConstants.PARAM_MODAL]: undefined,
      [RouteParamConstants.PARAM_NAVIGATE_TO]: undefined,
      [RouteParamConstants.PARAM_UPGRADE_TYPE]: undefined,
      [RouteParamConstants.PARAM_PURCHASE_SKU]: undefined,
      [RouteParamConstants.PARAM_PURCHASE_TYPE]: undefined,
    });
  };

  ssoLoginHandler = async () => {
    const ssoLinks = await SSOLinksInterface.getSSOLinks();

    this.props.setAuthCallbackIntent(AUTH_TYPES.LINK);
    let destinationUrl = ssoLinks[SSOLinksResponseKeys.LOGIN_URL];

    if (ENVIRONMENT_VARIABLES.SSO_DEV === 'true') {
      destinationUrl = destinationUrl.replace(
        new RegExp(`(redirect_uri=)(.*)(/${ENVIRONMENT_VARIABLES.SSO_CALLBACK_PATH})`),
        `$1${document.location.origin}$3`
      );
    }
    document.location = destinationUrl;
  };

  render() {
    const themeContext = this.context;
    return (
      <SUpgradeAccountFlow
        navigation={this.props.navigation}
        handleStarLogin={this.ssoLoginHandler}
        render={args => {
          const {
            layout,
            alertType,
            alertTitle,
            serverResponse,
            submitHandle,
            isLoading,
            flowBehaviour,
            layoutChangeHandler,
            step,
          } = args;

          let contentClassName = 'content';
          if (isLoading) contentClassName += ' loading';
          if (step === 2) contentClassName += ' fullWidthFields';

          return (
            <>
              <div className="header">
                <ImageBW
                  source={require(asset`Full_Modal_Header@3x.png`)}
                  ibw={require(asset`Full_Modal_Header.ibw`)}
                  style={{ position: 'absolute', width: '100%', height: '100%' }}
                  innerStyle={{}}
                  widthScale={0.5}
                />
                <div className="header-content">
                  <h1 className="title">{layout.steps[step].title}</h1>
                  <a className="helpLink" href={layout.header.helpLink} target="_blank" rel="noopener noreferrer">
                    {getText(TEXT_KEY.NEED_HELP)}
                  </a>
                </div>
              </div>

              <div className={contentClassName}>
                {step === 0 && serverResponse.length === 0 && (
                  <div className="subtitle">
                    {getText(TEXT_KEY.DETAILS_SHOULD_MATCH_DOC)}
                    <button onClick={this.ssoLoginHandler}>{getText(TEXT_KEY.CLICK_HERE)}</button>
                    {getText(TEXT_KEY.LOG_IN_AND_SKIP)}
                  </div>
                )}

                {serverResponse && serverResponse.length > 0 && (
                  <div className={`alert alert-${alertType || 'error'}`}>
                    <h3>{alertTitle}</h3>
                    {serverResponse}
                  </div>
                )}

                <AutoForm
                  layout={{ ...layout, header: {} }}
                  prev={this._cancelHandler}
                  step={step}
                  next={submitHandle}
                  serverResponse=""
                  flowBehaviour={flowBehaviour}
                  emitLayoutChange={layoutChangeHandler}
                  customInputStyle={themeContext.UpgradeAccountFlow.InputField}
                  customStyle={{ width: '100%' }}
                  customConfirmButton
                  formCustomContent
                  hideTopBar
                />
                {isLoading && (
                  <div className="loadingSpinner">
                    <Spinner animation="border" />
                  </div>
                )}
              </div>
            </>
          );
        }}
      />
    );
  }
}

UpgradeAccountFlow.contextType = ThemeContext;

