import React, { useContext } from 'react';
import Animated from 'animated/lib/targets/react-dom';
import {
  Styles,
  WheelSlice,
  WheelSliceContainer,
  SliceCurrencyIcon,
  SliceTextWrapper,
  TickerContaner,
  SkipButton,
  WheelButtonLogo,
} from './styledComponents';
import ImageBW from '../../../components/ImageBoundsWrapper';
import Button from '../../../components/Button/Button';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { SPINNER_ITEM_REWARD_TYPE } from 'shared/screens/DailySpin/utils';
import { useSelector } from 'shared/node_modules/react-redux';
import ThemeContext from 'shared/context/ThemeContext';

export default function Wheel(props) {
  const dailyWheelAssets = useSelector(state => state.assetsPackage.assets.DAILY_WHEEL);
  const {
    step,
    wheelInAnimDeg,
    wheelPostSpinAnimDeg,
    animateWheelIn,
    fastForwardButtonAnimDeg,
    spinnerItems,
    nextStepHandler,
    spinAnimDeg,
  } = props;

  const themeContext = useContext(ThemeContext);

  const icons = {
    coin: require(asset`images/Coin_Assets/Coin_Icon@3x.png`),
    coins: dailyWheelAssets.DAILY_SPIN_PRIZE_STACK,
    loyalty: require(asset`loyalty-icon@3x.png`),
  };
  return (
    <>
      <Animated.div
        className="wheel"
        style={{
          transform: [{ translateY: wheelInAnimDeg }, { translateX: wheelPostSpinAnimDeg }, { rotate: spinAnimDeg }],
        }}
      >
        {spinnerItems?.length > 0 && (
          <ImageBW
            innerClassName="WheelSliceContainer"
            source={dailyWheelAssets.DAILY_SPIN_WHEEL}
            ibw={require(asset`images/Daily_Spin/DailyBonus_wheel.ibw`)}
            style={Styles.wheelSliceContainer}
            innerStyle={Styles.wheelSliceContainerInner}
            innerProps={{
              onLoadEnd: animateWheelIn,
            }}
            ignoreSizeTransforms={true}
          >
            {spinnerItems?.map((item, index) => (
              <WheelSlice key={index} rowindex={index}>
                <WheelSliceContainer className="WheelSlice">
                  {item.REWARD === SPINNER_ITEM_REWARD_TYPE.REGULAR && (
                    <SliceCurrencyIcon src={icons[item.CURRENCY]} alt={item.CURRENCY} />
                  )}
                  <SliceTextWrapper
                    outstanding={item.REWARD === SPINNER_ITEM_REWARD_TYPE.OUTSTANDING ? 1 : 0}
                    theme={themeContext.DailySpin.WheelItems}
                    className="spinnerItemText"
                    data-label={item.DISPLAY_AMOUNT}
                  >
                    {item.DISPLAY_AMOUNT}
                  </SliceTextWrapper>
                </WheelSliceContainer>
              </WheelSlice>
            ))}
          </ImageBW>
        )}
      </Animated.div>
      {step <= 1 && (
        <SkipButton
          style={{
            bottom: fastForwardButtonAnimDeg,
          }}
        >
          <Button
            imageSource={dailyWheelAssets.DAILY_SPIN_WHEEL_SKIP_BTN}
            onClick={() => nextStepHandler(2500)}
            isDebounce={true}
            containerStyle={Styles.skipButton}
            imageStyle={Styles.skipButtonImage}
          />
        </SkipButton>
      )}
      <Animated.div
        className="wheel"
        style={{
          transform: [{ translateY: wheelInAnimDeg }, { translateX: wheelPostSpinAnimDeg }],
        }}
      >
        <ImageBW
          source={dailyWheelAssets.DAILY_SPIN_WHEEL_FRAME}
          ibw={require(asset`images/Daily_Spin/DailyBonus_wheel.ibw`)}
          style={Styles.wheelFrameAndTicker}
        />
        <ImageBW
          source={dailyWheelAssets.DAILY_SPIN_WHEEL_TICKER}
          ibw={require(asset`images/Daily_Spin/DailyBonus_wheel.ibw`)}
          style={Styles.wheelFrameAndTicker}
        >
          <TickerContaner />
        </ImageBW>

        <ImageBW
          style={Styles.spinButtonWrapper}
          source={dailyWheelAssets.DAILY_SPIN_WHEEL_SPIN_BTN}
          ibw={require(asset`images/Daily_Spin/DailyBonus_wheel.ibw`)}
          innerStyle={Styles.spinButtonWrapperInner}
        >
          {step !== 0 ? (
            <WheelButtonLogo src={dailyWheelAssets.DAILY_SPIN_WHEEL_BTN_LOGO} />
          ) : (
            <div className="spinText" data-label="SPIN" onClick={step === 0 ? () => nextStepHandler(1) : () => {}}>
              <span style={themeContext.DailySpin.SpinButtonText}>{getText(TEXT_KEY.SPIN)}</span>
            </div>
          )}
        </ImageBW>
      </Animated.div>
    </>
  );
}

