import React from 'react';
import styled from 'styled-components';
import SliderDot from './SliderDot';

function SliderIndicator({ lobbyPromotions, selectedSlide, onDotPress }) {
  return (
    <SliderDotWrapper>
      {lobbyPromotions.map((_, index) => (
        <SliderDot index={index} key={index} selected={selectedSlide === index} onPress={onDotPress} size={50} />
      ))}
    </SliderDotWrapper>
  );
}

export default SliderIndicator;

const SliderDotWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -2.8rem;
  z-index: 200;
  min-width: 300px;
  height: 60px;

  @media only screen and (max-height: 790px) {
    height: 40px;
    bottom: -2.2rem;
  }
`;

