import { useEffect, useRef } from 'react';

function useDragToScroll({ scrollBoxRef, isDraggableX, isDraggableY }) {
  const isDraggingRef = useRef(false);
  const startXRef = useRef(0);
  const startYRef = useRef(0);

  const handleMouseDown = e => {
    const container = scrollBoxRef.current?.container;
    if (container) container.style.cursor = 'grabbing';
    isDraggingRef.current = true;
    startXRef.current = e.pageX - container?.offsetLeft;
    startYRef.current = e.pageY - container?.offsetTop;
  };

  const handleMouseUp = () => {
    const container = scrollBoxRef.current?.container;
    if (container) container.style.cursor = 'auto';
    isDraggingRef.current = false;
  };

  const handleMouseLeave = () => {
    const container = scrollBoxRef.current?.container;
    if (container) container.style.cursor = 'auto';
    isDraggingRef.current = false;
  };

  const handleMouseMove = e => {
    const container = scrollBoxRef.current?.container;
    const scrollLeftPos = scrollBoxRef.current?.getScrollLeft();
    const scrollTopPos = scrollBoxRef.current?.getScrollTop();
    if (!isDraggingRef.current) return;
    e.preventDefault();
    const x = e.pageX - container?.offsetLeft;
    const y = e.pageY - container?.offsetTop;
    const walkX = (x - startXRef.current) * 0.07; // Adjust the multiplier for the desired scrolling speed
    const walkY = (y - startYRef.current) * 0.07; // Adjust the multiplier for the desired scrolling speed
    if (isDraggableX) scrollBoxRef.current.scrollLeft(scrollLeftPos - walkX);
    if (isDraggableY) scrollBoxRef.current.scrollTop(scrollTopPos - walkY);
  };

  useEffect(() => {
    if (!isDraggableX && !isDraggableY) return () => {};
    const container = scrollBoxRef.current?.container;
    container.addEventListener('mousedown', handleMouseDown);
    container.addEventListener('mouseup', handleMouseUp);
    container.addEventListener('mouseleave', handleMouseLeave);
    container.addEventListener('mousemove', handleMouseMove);

    return () => {
      container.removeEventListener('mousedown', handleMouseDown);
      container.removeEventListener('mouseup', handleMouseUp);
      container.removeEventListener('mouseleave', handleMouseLeave);
      container.removeEventListener('mousemove', handleMouseMove);
    };
  }, [scrollBoxRef]);

  return {};
}

export default useDragToScroll;

