import React, { Component } from 'react';
import styled from 'styled-components';
import SVideoAds from 'shared/screens/VideoAds';
import './VideoAds.scss';
import ThemeContext from 'shared/context/ThemeContext';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import CollectButton from './components/CollectButton';
import CongratsHeader from './components/WatchAndEarnHeader';

export default class Congrats extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-watchAndEarn-Congrats',
      hideBeforeSelector: ENVIRONMENT_VARIABLES.APPLICATION_TARGET === 'hri',
    };
  }

  state = {
    hasSeenCongratsModal: true,
  };

  componentDidMount() {
    window.history.replaceState('', '', window.location.origin);
  }

  render() {
    const themeContext = this.context;
    return (
      <SVideoAds
        navigation={this.props.navigation}
        render={props => {
          const { reward, collectHandler, setHasSeenCongratsModal } = props;
          if (this.state.hasSeenCongratsModal) {
            setHasSeenCongratsModal(true);
          }
          return (
            <div className="watchAndEarnCongratsContainer">
              <CongratsHeader title={getText(TEXT_KEY.CONGRATULATIONS)} />
              <span className="watchAndEarnPopupIcon">
                <CoinIcon alt={getText(TEXT_KEY.COINS)} />
              </span>
              <span style={themeContext.CongratulationsContainer.CongratsSpan}>
                {getText(TEXT_KEY.YOU_HAVE_EARNED)}
              </span>
              <AmountWrapper>
                <CoinIconSmall alt={getText(TEXT_KEY.COINS)} />
                <AmountTextWrapper
                  data-label={reward.amount}
                  className={`watchAndEarnGradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                >
                  {reward.amount}
                </AmountTextWrapper>
              </AmountWrapper>
              <CollectButton
                containerStyle={styles.collectButtonContainer}
                imageStyle={styles.collectButtonImage}
                textStyle={styles.collectButtonText}
                label={getText(TEXT_KEY.COLLECT_BUTTON)}
                onClick={() => collectHandler(true)}
              />
            </div>
          );
        }}
      />
    );
  }
}

Congrats.hideOverlay = true;
Congrats.hideCloseButton = true;
Congrats.contextType = ThemeContext;

const CoinIcon = styled.img.attrs(() => ({
  src: require(asset`images/Watch_and_Earn_Assets/Coins_Tex@3x.png`),
}))`
  width: 100%;
  height: 100%;
`;

const CoinIconSmall = styled.img.attrs(() => ({
  src: require(asset`Coin_Icon@3x.png`),
}))`
  width: 4%;
  height: 67%;
  margin-top: 0.1em;
  margin-right: 0.7em;
`;

const AmountWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 3em;
`;

const AmountTextWrapper = styled.span`
  font-size: 1.5em;
  font-weight: 600;
`;

const styles = {
  collectButtonContainer: {
    position: 'relative',
    textAlign: 'center',
    cursor: 'pointer',
    pointerEvents: 'auto',
    alignSelf: 'center',
    minHeight: '3em',
    width: '11em',
  },
  collectButtonImage: {
    width: '100%',
    height: '100%',
  },
  collectButtonText: {
    fontSize: 20,
    padding: 0,
  },
};

