import React, { Component } from 'react';
import SPurchaseSucceeded from 'shared/screens/PurchaseSucceeded';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { headerContainerStyle } from './components/styledComponents';
import Button from '../../components/Button/Button';
import PurhaseResultHeader from './components/PurchaseResultHeader';

export default class PurchaseSucceeded extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-purchase-succeeded',
    };
  }

  render() {
    return (
      <SPurchaseSucceeded
        navigation={this.props.navigation}
        {...this.props}
        render={args => {
          const { message, buttonHandler, vipPurchase } = args;

          return (
            <div qa-id="purchaseSuccess" className="SuccessMessageContainer">
              {!vipPurchase && (
                <>
                  <PurhaseResultHeader
                    containerStyle={headerContainerStyle}
                    title={getText(TEXT_KEY.PURCHASE_SUCCESSFUL)}
                  />

                  <img
                    src={require(asset`images/Purchase_Flow_Assets/Pile_of_Coins@3x.png`)}
                    style={{
                      width: '55%',
                      height: '60%',
                      objectFit: 'contain',
                    }}
                    alt=""
                  />
                  <div className="successHeader">{getText(TEXT_KEY.SUCCESS_RESPONSE)}</div>
                </>
              )}
              {vipPurchase && (
                <>
                  <img
                    className="congratulationsImage"
                    alt="Purchase succeeded"
                    src={require(asset`images/VIP_Assets/Purchase_Succeeded@3x.png`)}
                  />
                  <div style={{ position: 'relative', width: '100%', marginBottom: '1rem' }}>
                    <img
                      alt=""
                      src={require(asset`Light_Divider@3x.png`)}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        pointerEvents: 'none',
                      }}
                    />
                  </div>
                </>
              )}
              <div className="messageContainer">
                {vipPurchase && <div className="message">{getText(TEXT_KEY.ACCESS_GRANTED)}</div>}
                <div className="message">{message}</div>
              </div>
              <Button
                imageSource={require(asset`RoundedRect_Primary@3x.png`)}
                label={vipPurchase ? getText(TEXT_KEY.PLAY_BUTTON) : getText(TEXT_KEY.DONE)}
                onClick={buttonHandler}
                containerStyle={{ padding: '2rem' }}
                imageStyle={{
                  cursor: 'pointer',
                }}
              />
            </div>
          );
        }}
      />
    );
  }
}

PurchaseSucceeded.hideCloseButton = false;

