import theme from 'shared/assets/style/theme';
import defaultTheme from './defaultTheme';

const windowWidth = window.innerWidth;

const HriTheme = {
  ...defaultTheme,
  LimitedTimeOffer: {
    ...defaultTheme.LimitedTimeOffer,
    BannerContainer: {
      ...defaultTheme.LimitedTimeOffer.BannerContainer,
      marginBottom: 10,
    },
  },
  NavigationBar: {
    ...defaultTheme.NavigationBar,
    BarSection: {
      ...defaultTheme.NavigationBar.BarSection,
      paddingBottom: 10,
    },
    LoyaltyCurrencyWrapperStyle: {
      ...defaultTheme.NavigationBar.LoyaltyCurrencyWrapperStyle,
      position: 'absolute',
      right: windowWidth < 1300 ? 'calc(50% - 200px)' : 'calc(50% - 205px)',
    },
  },
  Tier: {
    ...defaultTheme.Tier,
    Card: {
      ...defaultTheme.Tier.Card,
      width: 75,
      top: -1,
    },
  },
  BonusLinkMessage: {
    ...defaultTheme.BonusLinkMessage,
    PromoImage: {
      ...defaultTheme.BonusLinkMessage.PromoImage,
      paddingTop: '376px',
    },
    BonusLinkButton: {
      ...defaultTheme.BonusLinkMessage.BonusLinkButton,
      textShadow: `1px 1px 0px ${theme.palette.secondary[0]}, 1px -1px 0px ${theme.palette.secondary[0]}, -1px 1px 0px ${theme.palette.secondary[0]}, -1px -1px 0px ${theme.palette.secondary[0]}`,
    },
  },
  OfferDetail: {
    ...defaultTheme.OfferDetail,
    RewardCenterPurchaseResponseHeader: {
      ...defaultTheme.OfferDetail.RewardCenterPurchaseResponseHeader,
      fontWeight: '600',
    },
    RewardCenterPurchaseResponseMessage: {
      ...defaultTheme.OfferDetail.RewardCenterPurchaseResponseMessage,
      fontSize: '1em',
    },
    ExclamationIcon: {
      ...defaultTheme.OfferDetail.ExclamationIcon,
      width: 126,
      height: 'auto',
    },
  },
  LinkAccountForm: {
    ...defaultTheme.LinkAccountForm,
    ScreenBackground: {
      backgroundImage: `url(${require(asset`Light_Divider@3x.png`)})`,
      backgroundSize: '80%',
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 'center',
      backgroundPositionY: 31,
    },
    HeaderTitle: {
      ...defaultTheme.LinkAccountForm.HeaderTitle,
      margin: 4,
    },
  },
  VIPItem: {
    ...defaultTheme.VIPItem,
    LogoImage: {
      height: '31%',
    },
    DurationFriendlyFull: {
      ...defaultTheme.VIPItem.DurationFriendlyFull,
      paddingTop: '10px',
    },
  },
  Settings: {
    ...defaultTheme.Settings,
    BackgroundLightDivider: {
      ...defaultTheme.Settings.BackgroundLightDivider,
      backgroundImage: `url(${require(asset`Light_Divider@3x.png`)})`,
      backgroundSize: '80%',
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 'center',
      backgroundPositionY: 31,
    },
  },
  Wheel: {
    ...defaultTheme.Wheel,
    Amount: {
      ...defaultTheme.Wheel.Amount,
      color: theme.palette.common[1],
      textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
    },
  },
  BottomBar: {
    ...defaultTheme.BottomBar,
    Divider: {
      ...defaultTheme.BottomBar.Divider,
      bottom: -10,
    },
  },
  StoreDownload: {
    ...defaultTheme.StoreDownload,
    Container: {
      ...defaultTheme.StoreDownload.Container,
      backgroundColor: 'black',
    },
  },
  RewardCenter: {
    ...defaultTheme.RewardCenter,
    RewardCenterMainContentHolder: {
      ...defaultTheme.RewardCenter.RewardCenterMainContentHolder,
      paddingLeft: '5px',
      paddingRight: '5px',
    },
  },
  CreditCardDetails: {
    ...defaultTheme.CreditCardDetails,
    CheckBoxBackgroundImage: {
      ...defaultTheme.CreditCardDetails.CheckBoxBackgroundImage,
      width: '40%',
    },
  },
  LobbyOverlay: {
    ...defaultTheme.LobbyOverlay,
    MainSceneContainer: {
      ...defaultTheme.LobbyOverlay.MainSceneContainer,
      paddingTop: '4.5rem',
    },
  },
  Start: {
    ...defaultTheme.Start,
    Logo: {
      ...defaultTheme.Start.Logo,
      marginTop: window.innerHeight > 700 ? '6%' : '0px',
    },
    MainContentContainer: {
      ...defaultTheme.Start.MainContentContainer,
      marginTop: window.innerHeight > 700 ? 30 : -20,
    },
    BottomBarFooterText: {
      ...defaultTheme.Start.BottomBarFooterText,
      marginTop: 10,
      textShadow: '2px 2px 2px #000000',
    },
  },
  CoinItem: {
    ...defaultTheme.CoinAmount,
    CoinAmount: {
      ...defaultTheme.CoinItem.CoinAmount,
      fontSize: '22px',
    },
    Extra: {
      ...defaultTheme.CoinItem.Extra,
      fontSize: '0.9vmin',
      marginTop: 0,
    },
  },
  ShopScreen: {
    ...defaultTheme.ShopScreen,
    ShopContainer: {
      ...defaultTheme.ShopScreen.ShopContainer,
      PackagesContainer: {
        ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer,
        padding: '6% 5.5rem 0.4rem',
        CoinItem: {
          ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer.CoinItem,
          left: '2px',
        },
      },
    },
  },
  MissionsProgress: {
    ...defaultTheme.MissionsProgress,
    MissionIcon: {
      ...defaultTheme.MissionsProgress.MissionIcon,
      top: -10,
    },
  },
  Bar: {
    ...defaultTheme.Bar,
    BarContainer: {
      ...defaultTheme.Bar.BarContainer,
      background: 'linear-gradient(180deg, rgba(33, 30, 28, 1) 0%, rgba(158, 136, 49, 1) 100%)',
    },
  },
};

export default HriTheme;

