import { connect } from '../../node_modules/react-redux';
import SessionExpired from './SessionExpired';
import { clearToken as _clearToken, setAuthorized as _setAuthorized } from '../../state/actions/auth';
import { setOnboardingFtueStep as _setOnboardingFtueStep } from '../../state/actions/currentOnboarding';
import { clearPrePurchaseStore as _clearPrePurchaseStore } from '../../state/actions/prePurchase';
import { clearSavedGameInfo as _clearSavedGameInfo } from '../../state/actions/game';
import { setLastPromoCode as _setLastPromoCode } from '../../state/actions/shopList';
import { cleanMissions as _cleanMissions } from '../../state/slices/missions/missions';
import { propertiesClearAssets } from '../../state/slices/properties/properties';
var mapStateToProps = function mapStateToProps(state) {
  return {
    ftueStep: state.currentOnboarding.ftueStep
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    setAuthorized: function setAuthorized(value) {
      dispatch(_setAuthorized(value));
    },
    clearToken: function clearToken() {
      dispatch(_clearToken());
    },
    setLastPromoCode: function setLastPromoCode(code) {
      dispatch(_setLastPromoCode(code));
    },
    setOnboardingFtueStep: function setOnboardingFtueStep(step) {
      dispatch(_setOnboardingFtueStep(step));
    },
    clearPrePurchaseStore: function clearPrePurchaseStore() {
      dispatch(_clearPrePurchaseStore());
    },
    clearSavedGameInfo: function clearSavedGameInfo() {
      dispatch(_clearSavedGameInfo());
    },
    clearAssets: function clearAssets() {
      dispatch(propertiesClearAssets());
    },
    cleanMissions: function cleanMissions() {
      dispatch(_cleanMissions());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SessionExpired);