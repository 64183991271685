import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/services/rtkQuery/axiosBaseQuery';
import { postOfferDetailMutation, postPurchaseOfferMutation, postRewardCenterMutation, postRewardCenterHistoryMutation, postRewardOfferHistoryDetailsMutation, postRewardOfferHistorySendEmailMutation } from './queries';
export var rewardCenterApi = createApi({
  reducerPath: 'rewardCenterQuery',
  baseQuery: axiosBaseQuery(),
  keepUnusedDataFor: 60,
  tagTypes: ['RewardCenter'],
  endpoints: function endpoints(builder) {
    return {
      postRewardCenter: builder.mutation(postRewardCenterMutation),
      postPurchaseOffer: builder.mutation(postPurchaseOfferMutation),
      postOfferDetail: builder.mutation(postOfferDetailMutation),
      postRewardCenterHistory: builder.mutation(postRewardCenterHistoryMutation),
      postRewardOfferHistoryDetails: builder.mutation(postRewardOfferHistoryDetailsMutation),
      postRewardOfferHistorySendEmail: builder.mutation(postRewardOfferHistorySendEmailMutation)
    };
  }
});
var usePostRewardCenterMutation = rewardCenterApi.usePostRewardCenterMutation,
  usePostPurchaseOfferMutation = rewardCenterApi.usePostPurchaseOfferMutation,
  usePostOfferDetailMutation = rewardCenterApi.usePostOfferDetailMutation,
  usePostRewardCenterHistoryMutation = rewardCenterApi.usePostRewardCenterHistoryMutation,
  usePostRewardOfferHistoryDetailsMutation = rewardCenterApi.usePostRewardOfferHistoryDetailsMutation,
  usePostRewardOfferHistorySendEmailMutation = rewardCenterApi.usePostRewardOfferHistorySendEmailMutation;
export { usePostRewardCenterMutation, usePostPurchaseOfferMutation, usePostOfferDetailMutation, usePostRewardCenterHistoryMutation, usePostRewardOfferHistoryDetailsMutation, usePostRewardOfferHistorySendEmailMutation };
var _rewardCenterApi$endp = rewardCenterApi.endpoints,
  postRewardCenter = _rewardCenterApi$endp.postRewardCenter,
  postPurchaseOffer = _rewardCenterApi$endp.postPurchaseOffer,
  postOfferDetail = _rewardCenterApi$endp.postOfferDetail,
  postRewardCenterHistory = _rewardCenterApi$endp.postRewardCenterHistory,
  postRewardOfferHistoryDetails = _rewardCenterApi$endp.postRewardOfferHistoryDetails,
  postRewardOfferHistorySendEmail = _rewardCenterApi$endp.postRewardOfferHistorySendEmail,
  rewardCenterReducerPath = rewardCenterApi.reducerPath,
  rewardCenterQueryReducer = rewardCenterApi.reducer,
  rewardCenterMiddleware = rewardCenterApi.middleware;
export { rewardCenterReducerPath, rewardCenterQueryReducer, rewardCenterMiddleware, postRewardCenter, postPurchaseOffer, postOfferDetail, postRewardCenterHistory, postRewardOfferHistoryDetails, postRewardOfferHistorySendEmail };