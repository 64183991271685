import styled from 'styled-components';

const headerBackground = require(asset`Full_Modal_Header@3x.png`);

export const HeaderContainer = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  height: 4rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-size: calc(100% + 2px) 100%;
  background-image: url(${headerBackground});
  ${({ theme }) => theme}
`;

export const HighlightHeaderTitle = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  margin-top: 5px;
  ${({ theme }) => theme}
`;

export const HighlightHeaderSubTitle = styled.div`
  font-size: 1rem;
  font-weight: 400;
  margin-top: -5px;
  margin-bottom: 5px;
`;

export const HeaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  ${({ theme }) => theme}
`;

export const HeaderHeight = styled.div`
  height: 4rem;
  ${({ theme }) => theme}
`;

export const SubTitle = styled.p`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: bold;
  ${({ theme }) => theme}
`;

