import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/services/rtkQuery/axiosBaseQuery';
import { getEventsQuery, postEventsMutation } from './queries';
export var eventsApi = createApi({
  reducerPath: ' eventsQuery',
  baseQuery: axiosBaseQuery(),
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  refetchOnMount: true,
  tagTypes: ['Events'],
  endpoints: function endpoints(builder) {
    return {
      getEvents: builder.query(getEventsQuery),
      postEvents: builder.mutation(postEventsMutation)
    };
  }
});
var useGetEventsQuery = eventsApi.useGetEventsQuery,
  usePostEventsMutation = eventsApi.usePostEventsMutation;
export { useGetEventsQuery, usePostEventsMutation };
var _eventsApi$endpoints = eventsApi.endpoints,
  getEvents = _eventsApi$endpoints.getEvents,
  postEvents = _eventsApi$endpoints.postEvents,
  eventsReducerPath = eventsApi.reducerPath,
  eventsQueryReducer = eventsApi.reducer,
  eventsMiddleware = eventsApi.middleware;
export { eventsReducerPath, eventsQueryReducer, eventsMiddleware, getEvents, postEvents };