import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/services/rtkQuery/axiosBaseQuery';
import { postSignupMutation, postSSOSignupMutation } from './queries';
export var signupApi = createApi({
  reducerPath: 'signupQuery',
  baseQuery: axiosBaseQuery(),
  endpoints: function endpoints(builder) {
    return {
      postSignup: builder.mutation(postSignupMutation),
      postSSOSignup: builder.mutation(postSSOSignupMutation)
    };
  }
});
var usePostSignupMutation = signupApi.usePostSignupMutation,
  usePostSSOSignupMutation = signupApi.usePostSSOSignupMutation;
export { usePostSignupMutation, usePostSSOSignupMutation };
var _signupApi$endpoints = signupApi.endpoints,
  postSignup = _signupApi$endpoints.postSignup,
  postSSOSignup = _signupApi$endpoints.postSSOSignup,
  signupReducerPath = signupApi.reducerPath,
  signupQueryReducer = signupApi.reducer,
  signupMiddleware = signupApi.middleware;
export { signupReducerPath, signupQueryReducer, signupMiddleware, postSignup, postSSOSignup };