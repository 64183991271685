import _toConsumableArray from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import { createSlice } from '@reduxjs/toolkit';
import { isEqual } from '../../../utils/object';
var sliceName = 'ACTION_COMMANDS';
var initialState = {
  actions: [],
  actionsLoading: false,
  lastActionExecuted: null
};
var actionCommandsSlice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    setActionCommand: function setActionCommand(state, action) {
      state.actions = [].concat(_toConsumableArray(state.actions), _toConsumableArray(action.payload.actions));
    },
    setActionCommandLoading: function setActionCommandLoading(state, action) {
      state.actionsLoading = action.payload;
    },
    setLastActionExecuted: function setLastActionExecuted(state, action) {
      state.lastActionExecuted = action.payload;
    },
    clearLastActionCommand: function clearLastActionCommand(state) {
      state.actions = state.actions.filter(function (action) {
        return !isEqual(action, state.lastActionExecuted);
      });
    },
    clearActionCommands: function clearActionCommands(state) {
      state.actions = [];
    }
  }
});
var actions = actionCommandsSlice.actions,
  reducer = actionCommandsSlice.reducer;
var setActionCommand = actions.setActionCommand,
  setActionCommandLoading = actions.setActionCommandLoading,
  clearLastActionCommand = actions.clearLastActionCommand,
  clearActionCommands = actions.clearActionCommands,
  setLastActionExecuted = actions.setLastActionExecuted;
export { setActionCommand, setActionCommandLoading, clearLastActionCommand, clearActionCommands, setLastActionExecuted };
export default reducer;