import _slicedToArray from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { createSlice } from '@reduxjs/toolkit';
import { ASSETS_KEYS } from '../../../constants';
import utils from '../../../utils';
var sliceName = 'ASSETS_PACKAGE';
var initialState = {
  data: [],
  errorMsg: '',
  assetsPackageId: null,
  lastRevisionNumber: {},
  assetsLoaded: false,
  assets: {}
};
var isWebPlatform = utils.getPlatform() === 'web';
var assetsPackageSlice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    assetsPackageRequestError: function assetsPackageRequestError(state, action) {
      state.errorMsg = action.payload;
    },
    assetsPackageRequestDataSuccess: function assetsPackageRequestDataSuccess(state, action) {
      state.data = action.payload;
      var _action$payload = action.payload,
        items = _action$payload.items,
        assetsPackageCategory = _action$payload.assetsPackageCategory,
        baseUrl = _action$payload.baseUrl;
      var _loop = function _loop() {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
          itemKey = _Object$entries$_i[0],
          itemValue = _Object$entries$_i[1];
        var assetKey = Object.keys(ASSETS_KEYS).find(function (key) {
          return ASSETS_KEYS[key] === itemKey;
        });
        if (assetKey) {
          state.assets[assetsPackageCategory] = state.assets[assetsPackageCategory] || {};
          state.assets[assetsPackageCategory][assetKey] = isWebPlatform ? "".concat(baseUrl).concat(itemKey, "?revision=").concat(itemValue) : {
            uri: "".concat(baseUrl).concat(itemKey, "?revision=").concat(itemValue)
          };
        }
      };
      for (var _i = 0, _Object$entries = Object.entries(items); _i < _Object$entries.length; _i++) {
        _loop();
      }
    },
    assetsPackageRequestListDataSuccess: function assetsPackageRequestListDataSuccess(state, action) {
      for (var _i2 = 0, _Object$values = Object.values(action.payload); _i2 < _Object$values.length; _i2++) {
        var assetPackage = _Object$values[_i2];
        state.data = assetPackage;
        var items = assetPackage.items,
          assetsPackageCategory = assetPackage.assetsPackageCategory,
          assetsPackageId = assetPackage.assetsPackageId,
          baseUrl = assetPackage.baseUrl;
        var _loop2 = function _loop2() {
          var _Object$entries2$_i = _slicedToArray(_Object$entries2[_i3], 2),
            itemKey = _Object$entries2$_i[0],
            itemValue = _Object$entries2$_i[1];
          var assetKey = Object.keys(ASSETS_KEYS).find(function (key) {
            return ASSETS_KEYS[key] === itemKey;
          });
          if (assetKey) {
            state.assets[assetsPackageCategory] = state.assets[assetsPackageCategory] || {};
            state.assets[assetsPackageCategory][assetsPackageId] = state.assets[assetsPackageCategory][assetsPackageId] || {};
            state.assets[assetsPackageCategory][assetsPackageId][assetKey] = isWebPlatform ? "".concat(baseUrl).concat(itemKey, "?revision=").concat(itemValue) : {
              uri: "".concat(baseUrl).concat(itemKey, "?revision=").concat(itemValue)
            };
          }
        };
        for (var _i3 = 0, _Object$entries2 = Object.entries(items); _i3 < _Object$entries2.length; _i3++) {
          _loop2();
        }
      }
    },
    setAssetsLoaded: function setAssetsLoaded(state, action) {
      state.assetsLoaded = action.payload;
    },
    setStockControl: function setStockControl(state, action) {
      state.assetsPackageId = action.payload.assetsPackageId;
      state.lastRevisionNumber = action.payload.lastRevisionNumber;
    }
  }
});
var actions = assetsPackageSlice.actions,
  reducer = assetsPackageSlice.reducer;
var assetsPackageRequestError = actions.assetsPackageRequestError,
  assetsPackageRequestDataSuccess = actions.assetsPackageRequestDataSuccess,
  assetsPackageRequestListDataSuccess = actions.assetsPackageRequestListDataSuccess,
  setAssetsLoaded = actions.setAssetsLoaded,
  setStockControl = actions.setStockControl;
export { assetsPackageRequestError, assetsPackageRequestDataSuccess, assetsPackageRequestListDataSuccess, setAssetsLoaded, setStockControl };
export default reducer;