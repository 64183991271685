import styled from 'styled-components';

const headerBackground = require(asset`Full_Modal_Header@3x.png`);

export const HeaderContainer = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  height: 3.7rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background-size: calc(100% + 2px) 100%;
  background-image: url(${headerBackground});
`;

export const HeaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
`;

export const HeaderHeight = styled.div`
  height: 3.7rem;
`;

