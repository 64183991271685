import styled from 'styled-components';
export const GameListWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  margin-top: 2rem;
  padding-bottom: 2rem;
  ${({ banner }) =>
    banner
      ? `
      margin-left: 30vw;
        `
      : `
      margin-left: 8vw;
        `}
`;

