import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/services/rtkQuery/axiosBaseQuery';
import { getUrlsQuery } from './queries';
export var urlsApi = createApi({
  reducerPath: 'urlsQuery',
  baseQuery: axiosBaseQuery(),
  keepUnusedDataFor: 60,
  tagTypes: ['Urls'],
  endpoints: function endpoints(builder) {
    return {
      getUrls: builder.query(getUrlsQuery)
    };
  }
});
var useGetUrlsQuery = urlsApi.useGetUrlsQuery,
  useLazyGetUrlsQuery = urlsApi.useLazyGetUrlsQuery;
export { useGetUrlsQuery, useLazyGetUrlsQuery };
var getUrls = urlsApi.endpoints.getUrls,
  urlsReducerPath = urlsApi.reducerPath,
  urlsQueryReducer = urlsApi.reducer,
  urlsMiddleware = urlsApi.middleware;
export { urlsReducerPath, urlsQueryReducer, urlsMiddleware, getUrls };