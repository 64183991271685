import React, { useEffect } from 'react';
import { connect } from 'shared/node_modules/react-redux';
import { setUserAffId, setInviteLink } from 'shared/state/actions/playerInfo';
import { setToken, setRestart } from 'shared/state/actions/auth';
import { setPlayerId } from 'shared/state/actions/playerInfo';
import generateRender from 'shared/utils/generateRender';
import Star from './Star';
import Hri from './Hri';
import DefaultStart from './DefaultStart';
import MiddleLayout from './MiddleLayout';
import RouteParamConstants from 'shared/utils/routeParamConstants';

const Start = props => {
  const { navigation, setInviteLink } = props;

  const handleInviteLink = () => {
    const inviteLink = navigation.getParam(RouteParamConstants.PARAM_INVITE_LINK);
    if (inviteLink) setInviteLink(inviteLink);
  };

  useEffect(handleInviteLink, []);

  const StartComponent = generateRender({
    default: DefaultStart,
    star: Star,
    hri: Hri,
    millelacs: MiddleLayout,
  });

  return <StartComponent {...props} />;
};
Start.path = '';

const mapDispatchToProps = {
  setUserAffId,
  setRestart,
  setToken,
  setPlayerId,
  setInviteLink,
};

const mapStateToProps = state => {
  return {
    googleToken: state.token.google_token,
    googleAuth: state.availableServices.googleAuth,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Start);

