import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SET_AUTHORIZED, SET_TOKEN, SET_RESTART, CLEAR_TOKEN, SET_LAST_ACTIVE, SET_FB_TOKEN, UPDATE_LAST_LOGIN_INFO, CLEAR_LOGIN_INFO, CLEAR_GOOGLE_TOKEN, SET_GOOGLE_TOKEN } from '../actions/types';
var initialState = {
  token: '',
  fb_token: '',
  google_token: '',
  lastLoginInfo: {}
};
export var fbTokenReducer = function fbTokenReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_FB_TOKEN:
      return _objectSpread(_objectSpread({}, state), {}, {
        fb_token: action.payload
      });
    case CLEAR_TOKEN:
      return _objectSpread(_objectSpread({}, state), {}, {
        fb_token: ''
      });
    default:
      return state;
  }
};
export var tokenReducer = function tokenReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_TOKEN:
      return _objectSpread(_objectSpread({}, state), {}, {
        token: action.payload
      });
    case CLEAR_TOKEN:
      return _objectSpread(_objectSpread({}, state), {}, {
        token: ''
      });
    case UPDATE_LAST_LOGIN_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        lastLoginInfo: action.payload
      });
    case CLEAR_LOGIN_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        lastLoginInfo: {}
      });
    case SET_GOOGLE_TOKEN:
      return _objectSpread(_objectSpread({}, state), {}, {
        google_token: action.payload
      });
    case CLEAR_GOOGLE_TOKEN:
      return _objectSpread(_objectSpread({}, state), {}, {
        google_token: ''
      });
    default:
      return state;
  }
};
export var restartReducer = function restartReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_RESTART:
      return _objectSpread(_objectSpread({}, state), {}, {
        token: action.payload
      });
    case CLEAR_TOKEN:
      return _objectSpread(_objectSpread({}, state), {}, {
        token: ''
      });
    default:
      return state;
  }
};
export var authorizedReducer = function authorizedReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    authorized: false
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_AUTHORIZED:
      return _objectSpread(_objectSpread({}, state), {}, {
        authorized: action.payload
      });
    default:
      return state;
  }
};
export var lastActiveReducer = function lastActiveReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    timestamp: null
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_LAST_ACTIVE:
      return _objectSpread(_objectSpread({}, state), {}, {
        timestamp: action.payload
      });
    case CLEAR_TOKEN:
      return _objectSpread(_objectSpread({}, state), {}, {
        timestamp: ''
      });
    default:
      return state;
  }
};