import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SET_PRE_PURCHASE_ROUTE, SET_CARD_NICKNAME, SET_PROMO_CODE, CLEAR_PRE_PURCHASE_STORE, CLEAR_SENSITIVE_INFO, SET_PRE_PURCHASE_ROUTE_PARAMS, SET_PURCHASE_TYPE, IS_OUT_OF_COINS_PURCHASE, IS_LTO_PURCHASE, SET_FORM_URL, SET_PURCHASE_PROFILE, SET_PRE_PURCHASE_DATA } from '../actions/types';
var initialState = {
  cardNickname: '',
  route: '',
  routeParams: {},
  promoCode: '',
  purchaseType: '',
  outOfCoinsPurchase: false,
  isLTOPurchase: false,
  purchaseProfile: [],
  creditCardType: '',
  lastDigits: ''
};
export var prePurchaseReducer = function prePurchaseReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_PRE_PURCHASE_ROUTE:
      return _objectSpread(_objectSpread({}, state), {}, {
        route: action.payload
      });
    case SET_PRE_PURCHASE_ROUTE_PARAMS:
      return _objectSpread(_objectSpread({}, state), {}, {
        routeParams: action.payload
      });
    case SET_CARD_NICKNAME:
      return _objectSpread(_objectSpread({}, state), {}, {
        cardNickname: action.payload
      });
    case SET_PROMO_CODE:
      return _objectSpread(_objectSpread({}, state), {}, {
        promoCode: action.payload
      });
    case SET_PURCHASE_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        purchaseType: action.payload
      });
    case IS_OUT_OF_COINS_PURCHASE:
      return _objectSpread(_objectSpread({}, state), {}, {
        outOfCoinsPurchase: action.payload
      });
    case IS_LTO_PURCHASE:
      return _objectSpread(_objectSpread({}, state), {}, {
        ltoPurchase: action.payload
      });
    case SET_FORM_URL:
      return _objectSpread(_objectSpread({}, state), {}, {
        formUrl: action.payload
      });
    case SET_PURCHASE_PROFILE:
      return _objectSpread(_objectSpread({}, state), {}, {
        purchaseProfile: action.payload
      });
    case SET_PRE_PURCHASE_DATA:
      return _objectSpread(_objectSpread({}, state), action.payload);
    case CLEAR_SENSITIVE_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        creditCardType: '',
        lastDigits: ''
      });
    case CLEAR_PRE_PURCHASE_STORE:
      return initialState;
    default:
      return state;
  }
};