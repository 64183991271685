import { createApi } from '@reduxjs/toolkit/query/react';
import { postLinkGuestAccountMutation } from './queries';
import { axiosBaseQuery } from '../../api/services/rtkQuery/axiosBaseQuery';
export var linkGuestAccountApi = createApi({
  reducerPath: 'linkGuestAccountQuery',
  baseQuery: axiosBaseQuery(),
  endpoints: function endpoints(builder) {
    return {
      postLinkGuestAccount: builder.mutation(postLinkGuestAccountMutation)
    };
  }
});
var usePostLinkGuestAccountMutation = linkGuestAccountApi.usePostLinkGuestAccountMutation;
export { usePostLinkGuestAccountMutation };
var postLinkGuestAccount = linkGuestAccountApi.endpoints.postLinkGuestAccount,
  linkGuestAccountReducerPath = linkGuestAccountApi.reducerPath,
  linkGuestAccountQueryReducer = linkGuestAccountApi.reducer,
  linkGuestAccountMiddleware = linkGuestAccountApi.middleware;
export { linkGuestAccountReducerPath, linkGuestAccountQueryReducer, linkGuestAccountMiddleware, postLinkGuestAccount };