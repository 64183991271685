import React from 'react';
import Button from '../../../../components/Button/Button';
import { styles } from './styles';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import { getText, TEXT_KEY } from 'shared/utils/localization';

const TiFormButton = props => {
  const { handleLinkBtn, handlePinAndAccount, error } = props;

  return (
    <Button
      onClick={() => {
        if (!error) handleLinkBtn(handlePinAndAccount);
      }}
      label={getText(TEXT_KEY.LINK)}
      containerStyle={styles.buttonContainerStyle}
      imageSource={require(asset`RoundedRect_Secondary@2x.png`)}
      textStroke={BUTTON_STROKES.SECONDARY}
    />
  );
};

export default TiFormButton;

