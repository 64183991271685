import Favourites from './Favourites';
import { connect } from '../../node_modules/react-redux';
import { setAppBackground as _setAppBackground } from '../../state/actions/app';
import { setFavouriteGames as _setFavouriteGames, setGameId as _setGameId } from '../../state/actions/game';
var mapStateToProps = function mapStateToProps(state) {
  return {
    favouriteGames: state.favouriteGames,
    lobbyPromotions: state.promotions.lobbyNews,
    slotGames: state.slotGames,
    tableGames: state.tableGames,
    vipGames: state.vipGames
  };
};
var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    setAppBackground: function setAppBackground(background) {
      dispatch(_setAppBackground(background));
    },
    setGameId: function setGameId(gameId) {
      dispatch(_setGameId(gameId));
    },
    setFavouriteGames: function setFavouriteGames(gameList) {
      dispatch(_setFavouriteGames(gameList));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Favourites);