import styled from 'styled-components';
import Button from '../../../components/Button/Button';
import generateRender from 'shared/utils/generateRender';

const ButtonSrc = {
  DISABLED: require(asset`RoundedRect_Disabled@3x.png`),
  PRIMARY: require(asset`RoundedRect_Primary@3x.png`),
  DISABLED_WIDE: require(asset`roundedrect-disabled-wide@3x.png`),
  PRIMARY_WIDE: require(asset`roundedrect-primary-wide@3x.png`),
};

const LoginButtonDefault = styled(Button).attrs(({ isValid }) => ({
  imageSource: isValid ? ButtonSrc.PRIMARY : ButtonSrc.DISABLED,
  containerStyle: { height: '100px' },
}))`
  cursor: pointer;
  font-size: 1.7rem;
  font-weight: 600;
  width: 181px;
  height: 59px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
`;

const LoginButtonMillelacs = styled(Button).attrs(({ isValid }) => ({
  imageSource: isValid ? ButtonSrc.PRIMARY_WIDE : ButtonSrc.DISABLED_WIDE,
  imageStyle: {
    width: '35vh',
    height: '6vh',
  },
  containerStyle: { height: '6vh', marginTop: '30px' },
}))`
  cursor: pointer;
  font-size: 1.7rem;
  font-weight: 600;
  width: 181px;
  height: 59px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
`;

const LoginButton = generateRender({
  default: LoginButtonDefault,
  millelacs: LoginButtonMillelacs,
});

export default LoginButton;

