import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import ImageBW from '../../../../components/ImageBoundsWrapper';
import { HeaderContainer, HeaderWrapper, HeaderHeight } from './styleComponents';

const HriLevelUpText = () => {
  return (
    <>
      <HeaderWrapper>
        <HeaderContainer>
          <div style={{ marginBottom: -5 }} className="levelUpTitle">
            {getText(TEXT_KEY.LEVEL_UP_TEXT)}
          </div>
        </HeaderContainer>
      </HeaderWrapper>
      <HeaderHeight />
      <ImageBW
        source={require(asset`Shop_VIP_Divider_Glows.png`)}
        ibw={require(asset`Shop_VIP_Divider_Glows.ibw`)}
        style={{
          width: '100%',
          height: '2.5rem',
          objectFit: 'contain',
          position: 'absolute',
          top: '3.7rem',
        }}
      />
    </>
  );
};

export default HriLevelUpText;

