import React from 'react';
import styled from 'styled-components';
import NotificationBadge from '../../../../components/BottomBar/components/NotificationBadge';
import { isEmpty } from 'shared/utils/object';

const SocialHeaderTab = ({ onClick, title, activeTab, count }) => {
  return (
    <>
      <BackgroundActive src={require(asset`images/Tabs_Assets/tab-front.png`)} activeTab={activeTab} />
      <BackgroundInactive src={require(asset`images/Tabs_Assets/tab-back.png`)} activeTab={activeTab} />
      <Clickable onClick={onClick}>
        <Title>{title}</Title>
        {!isEmpty(count) && <NotificationBadge count={count} theme={BadgeStyles} />}
      </Clickable>
    </>
  );
};

export default SocialHeaderTab;

const BackgroundActive = styled.img`
  width: 85%;
  height: 92%;
  position: absolute;
  z-index: 1;
  bottom: 0.3rem;
  ${({ activeTab }) => activeTab && `transform: rotateY(180deg);`}
`;

const BackgroundInactive = styled.img`
  width: 85%;
  height: 92%;
  position: absolute;
  bottom: 0.3rem;
  ${({ activeTab }) => activeTab && `transform: rotateY(180deg);`}
`;

const Clickable = styled.div`
  z-index: 10;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Title = styled.h4`
  margin: 0 auto;
  font-size: 1.8rem;
  background: linear-gradient(to bottom, rgb(247, 238, 209) 35%, rgb(223, 195, 111) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  text-stroke: 0.5px #000;
  -webkit-text-stroke: 0.5px #000;
  text-shadow: -1px 1px 15px rgba(223, 195, 111, 0.5);
  text-transform: uppercase;
`;

const BadgeStyles = {
  position: 'relative',
  right: '9.5rem',
  height: '100%',
};

