import React, { useState } from 'react';
import ModalContentWrapper from './ModalContentWrapper';
import ErrorHandlingWrapper from './ErrorHandlingWrapper';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import SocialLoginInterface from 'shared/api/SocialLoginInterface';
import { EVENT_EMITTER_TYPE } from 'shared/constants';
import { LinkImageButton, ImageButtonWrapper } from '../../../styledComponents';
import { usePostLinkGuestAccountMutation } from 'shared/state/query/linkGuestAccount';
import { AUTH_TYPES } from 'shared/utils/authFlow/constants';
import { LINK_ACCOUNT_ERROR_TYPES } from 'shared/constants/linkAccountErrorTypes';
import { ACTION_COMMANDS_EVENT_TYPE, ACTION_COMMANDS_TYPE } from 'shared/api/ActionCommandsInterface/constants';
import Emitter from 'shared/services/EmitterService';

export default function FacebookLinkOption(props) {
  const { isActionCommand = false, modalType, onDisplayAction, facebookLinkButtonStyles } = props;
  const [postLinkGuestAccount] = usePostLinkGuestAccountMutation();
  const [disabledButton, changeButtonState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(defaultErrorObj);

  const retryCallBack = () => {
    setShowModal(false);
    checkFacebookLogin();
  };

  const checkFacebookLogin = async () => {
    if (isActionCommand)
      Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
        actionType: ACTION_COMMANDS_TYPE.SUBMIT_EVENT_REQUEST,
        data: { event: ACTION_COMMANDS_EVENT_TYPE.SIGN_IN_FACEBOOK_APP_MODAL, modalType, onDisplayAction },
      });

    changeButtonState(true);
    const { success, error, facebookUser } = await SocialLoginInterface.facebookLoginHandlerForLinkAccount(FB);
    if (!success) {
      setShowModal(true);
      changeButtonState(false);
      setErrorMessage({ error, type: LINK_ACCOUNT_ERROR_TYPES.THIRD_PARTY_ERROR });
    } else {
      postLinkGuestAccount({
        auth_token: facebookUser.accessToken,
        loginMethod: AUTH_TYPES.FACEBOOK,
      })
        .unwrap()
        .then(() => changeButtonState(false))
        .catch(error => {
          setErrorMessage({
            error: error?.data?.message || generalLoginError(),
            type: LINK_ACCOUNT_ERROR_TYPES.SERVER_ERROR,
          });
          setShowModal(true);
          changeButtonState(false);
        });
    }
  };

  return (
    <>
      <ImageButtonWrapper lock={disabledButton} onClick={() => (!disabledButton ? checkFacebookLogin() : null)}>
        <LinkImageButton
          src={require(asset`./images/Link_Guest_Account/facebook.png`)}
          styled={facebookLinkButtonStyles}
        />
      </ImageButtonWrapper>
      <ModalContentWrapper isVisible={showModal} setModalState={setShowModal}>
        <ErrorHandlingWrapper
          setModalState={setShowModal}
          buttonClickHandler={errorMessage.type === LINK_ACCOUNT_ERROR_TYPES.THIRD_PARTY_ERROR ? retryCallBack : false}
          error={errorMessage.error}
          buttonText={
            errorMessage.type === LINK_ACCOUNT_ERROR_TYPES.THIRD_PARTY_ERROR
              ? getText(TEXT_KEY.TRY_AGAIN)
              : getText(TEXT_KEY.CONTACT_BUTTON)
          }
        />
      </ModalContentWrapper>
    </>
  );
}

const generalLoginError = () => getText(TEXT_KEY.GENERIC_LOGIN_ERROR_MESSAGE);
const defaultErrorObj = {
  error: '',
  type: '',
};

