import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/services/rtkQuery/axiosBaseQuery';
import { getAssetsPackageQuery, getAssetsPackageListQuery } from './queries';
export var assetsPackageApi = createApi({
  reducerPath: 'assetsPackageQuery',
  baseQuery: axiosBaseQuery(),
  keepUnusedDataFor: 5,
  tagTypes: ['AssetsPackage'],
  endpoints: function endpoints(builder) {
    return {
      getAssetsPackage: builder.query(getAssetsPackageQuery),
      getAssetsPackageList: builder.query(getAssetsPackageListQuery)
    };
  }
});
var useGetAssetsPackageQuery = assetsPackageApi.useGetAssetsPackageQuery;
export { useGetAssetsPackageQuery };
var _assetsPackageApi$end = assetsPackageApi.endpoints,
  getAssetsPackage = _assetsPackageApi$end.getAssetsPackage,
  getAssetsPackageList = _assetsPackageApi$end.getAssetsPackageList,
  assetsPackageReducerPath = assetsPackageApi.reducerPath,
  assetsPackageQueryReducer = assetsPackageApi.reducer,
  assetsPackageMiddleware = assetsPackageApi.middleware;
export { assetsPackageReducerPath, assetsPackageQueryReducer, assetsPackageMiddleware, getAssetsPackage, getAssetsPackageList };