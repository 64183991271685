import React, { Component } from 'react';
import styled from 'styled-components';
import GamePage from '../../components/GamePage';
import SFavourites from 'shared/screens/Favourites';
import Utils from 'shared/utils';
import audio from 'shared/utils/audio';
import { GameListWrapper } from '../Home/styledComponents';

export default class Favourites extends Component {
  handleNextClick = () => {
    audio.onClick();
    this.slider.current.slickNext();
  };

  handlePrevClick = () => {
    audio.onClick();
    this.slider.current.slickPrev();
  };

  handleFirstClick = () => {
    audio.onClick();
    this.slider.current.slickGoTo(0);
  };

  handleLastClick = pagesCount => {
    audio.onClick();
    this.slider.current.slickGoTo(pagesCount);
  };

  render() {
    return (
      <SFavourites
        navigation={this.props.navigation}
        {...this.props}
        render={args => {
          const { pagedGames, lobbyPromotions } = args;
          const hasPromotions = !Utils.isEmpty(lobbyPromotions);
          const hasGames = !Utils.isEmpty(pagedGames);

          return (
            <div className="roomPage">
              <div className="roomContent">
                <GameListWrapper banner={hasPromotions}>
                  {!hasGames && (
                    <EmptyFavoritesContainer>
                      <EmptyFavoritesImage
                        src={require(asset`images/Empty_favorites_Message/Empty_Info@3x.png`)}
                        alt="Empty Favourites Message"
                        banner={hasPromotions}
                      />
                    </EmptyFavoritesContainer>
                  )}
                  {hasGames && (
                    <div className={'GameListContainer'}>
                      <GamePage {...args} navigation={this.props.navigation} hasPromotions={hasPromotions} />
                    </div>
                  )}
                </GameListWrapper>
              </div>
            </div>
          );
        }}
      />
    );
  }
}

const EmptyFavoritesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 2.5;
  margin-top: 1rem;
`;

const EmptyFavoritesImage = styled.img`
  height: 90%;
`;

