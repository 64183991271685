import generateRender from 'shared/utils/generateRender';
import DefaultPreloader from './DefaultPreloader';
import HriPreloader from './HriPreloader';

const AssetPreloader = generateRender({
  default: DefaultPreloader,
  hri: HriPreloader,
});

export default AssetPreloader;
