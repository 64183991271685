import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import Button from '../../../components/Button/Button';
import generateRender from 'shared/utils/generateRender';

function FormButtonDefault(props) {
  const { isLandingPage, disabled } = props;
  return (
    <Button
      imageStyle={isLandingPage ? { width: '15em' } : null}
      label={getText(TEXT_KEY.REGISTER)}
      imageSource={
        !disabled
          ? isLandingPage
            ? require(asset`images/TrackingUrl/Green_Button_landing@3x.png`)
            : require(asset`RoundedRect_Primary@3x.png`)
          : isLandingPage
          ? require(asset`images/TrackingUrl/Grey_Button_landing@3x.png`)
          : require(asset`RoundedRect_Disabled@3x.png`)
      }
      {...props}
    />
  );
}

function FormButtonMillelacs(props) {
  const { disabled } = props;
  return (
    <Button
      imageSource={
        !disabled ? require(asset`roundedrect-primary-wide@3x.png`) : require(asset`roundedrect-disabled-wide@3x.png`)
      }
      imageStyle={{ width: '18em' }}
      {...props}
      label={getText(TEXT_KEY.SIGN_UP)}
      textStroke={BUTTON_STROKES.PRIMARY}
    />
  );
}

const FormButton = generateRender({
  default: FormButtonDefault,
  millelacs: FormButtonMillelacs,
});

export default FormButton;

