import React, { useEffect, useReducer, useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import {
  SplashScreen,
  StartScreenWrapper,
  LogoImage,
  MainContentContainer,
  MainContentHeader,
  SignInButtonWrapper,
  SignInButtonImage,
  GuestLoginButtonWrapper,
} from './styledComponents';
import Styles from './styles';
import RouteParamConstants from 'shared/utils/routeParamConstants';
import SStart from 'shared/screens/Start';
import audio from 'shared/utils/audio';
import Signup from '../Signup';
import ResetPassword from '../ResetPassword';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import ThemeContext from 'shared/context/ThemeContext';
import TermsModal from '../../components/TermsModal';
import SocialLoginInterface from 'shared/api/SocialLoginInterface';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import Button from '../../components/Button/Button';
import { BottomBarComponent } from './components/BottomBar';
import TermsModalWrapper from './../../components/TermsModal/TermsModalComponents/TermsModalWrapper';

const DefaultStart = props => {
  const activeBG = require(asset`RoundedRect_Primary@3x.png`);
  const nonActiveBG = require(asset`RoundedRect_Disabled@3x.png`);
  const activeCheckbox = require(asset`Checkbox@3x.png`);
  const nonActiveCheckbox = require(asset`Checkbox_Deselected@3x.png`);
  const { Start } = useContext(ThemeContext);

  const isGuestLoginButton = ENVIRONMENT_VARIABLES.PLAY_AS_GUEST_TYPE === 'BUTTON';

  const [state, setState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      oauthLoading: false,
      statusMessage: null,
      emailPermissionAccepted: null,
      socialError: null,
      socialErrorType: null,
      playAsGuest: ENVIRONMENT_VARIABLES.PLAY_AS_GUEST,
      error: null,
    }
  );

  useEffect(() => {
    if (props.navigation.getParam(RouteParamConstants.PARAM_ACTION)) {
      _handleActionRedirect();
    }
    if (props.navigation.getParam(RouteParamConstants.PARAM_TRACKING_URL_AFF_ID)) {
      let aff_Id = props.navigation.getParam(RouteParamConstants.PARAM_TRACKING_URL_AFF_ID);
      Array.isArray(aff_Id) ? props.setUserAffId(aff_Id[0]) : props.setUserAffId(aff_Id);
    }
  }, []);

  const _handleActionRedirect = () => {
    window.location.assign(props.navigation.getParam(RouteParamConstants.PARAM_ACTION));
  };

  useEffect(() => {
    props.googleToken &&
      SocialLoginInterface.googleLoginHandler({
        setSuccessState: setSuccessState,
        setErrorState: setErrorState,
        setCanceledState: setCanceledState,
      });
  }, [props.googleToken]);

  const setSuccessState = () => {
    setState({
      oauthLoading: true,
      statusMessage: null,
      socialError: null,
      socialErrorType: null,
      error: null,
    });
  };

  const setErrorState = () => {
    setState({
      oauthLoading: false,
      statusMessage: null,
      socialError: null,
      socialErrorType: null,
      error: true,
    });
  };

  const setCanceledState = ({ statusMessage, emailPermissionAccepted, socialErrorType }) => {
    setState({
      oauthLoading: false,
      statusMessage,
      emailPermissionAccepted,
      socialError: true,
      socialErrorType,
      error: null,
    });
  };

  const closeModal = () => {
    audio.onClick();
    setState({
      statusMessage: null,
      socialError: false,
      socialErrorType: null,
      error: null,
    });
  };

  return (
    <SStart
      navigation={props.navigation}
      mountIntent={{
        facebook: () =>
          SocialLoginInterface.facebookLoginHandler({
            FB,
            emailPermissionAccepted: state.emailPermissionAccepted,
            setSuccessState: setSuccessState,
            setErrorState: setErrorState,
            setCanceledState: setCanceledState,
          }),
      }}
      render={args => {
        const {
          onGuestActivate,
          onAccountActivate,
          onFacebookActivate,
          isVisible,
          terms,
          isLoading,
          closeModal: argsCloseModal,
          onSubmit,
          setTerms,
          termsAndConditionsUrl,
          privacyPolicyUrl,
          showSignUpForm,
          signupHandler,
          recoverPasswordResponse,
          hasFacebook,
        } = args;

        return (
          <SplashScreen className="SplashScreen">
            <Signup
              isModal
              isVisible={showSignUpForm}
              title={getText(TEXT_KEY.SIGNUP_TITLE)}
              closeModal={argsCloseModal}
              closeRetryModal={closeModal}
              zIndex={101}
              modalStyle={Styles.modalStyle}
              modalContainerStyle={Styles.modalContainerStyle}
              modalMainBodyStyle={Styles.modalMainBodyStyle}
              titleStyle={Styles.titleStyle}
              termsAndConditionsUrl={termsAndConditionsUrl}
              privacyPolicyUrl={privacyPolicyUrl}
              navigation={props.navigation}
            />
            <ResetPassword
              isVisible={recoverPasswordResponse.visible}
              closeModal={argsCloseModal}
              zIndex={101}
              navigation={props.navigation}
              invalid={recoverPasswordResponse.invalid}
              errorMessage={recoverPasswordResponse.message}
              recoverId={recoverPasswordResponse.id}
              recoverToken={recoverPasswordResponse.token}
              getTextFunc={getText}
              textKeys={TEXT_KEY}
              TermsModalWrapper={TermsModalWrapper}
            />
            <TermsModal
              isVisible={isVisible}
              closeModal={argsCloseModal}
              closeRetryModal={closeModal}
              terms={terms}
              activeCheckbox={activeCheckbox}
              nonActiveCheckbox={nonActiveCheckbox}
              setTerms={setTerms}
              onSubmit={onSubmit}
              activeBG={activeBG}
              nonActiveBG={nonActiveBG}
              onFacebookActivate={onFacebookActivate}
              error={state.error}
              socialError={state.socialError}
              socialErrorType={state.socialErrorType}
              statusMessage={state.statusMessage}
              clientText={getText(TEXT_KEY.CLIENT_TITLE)}
              termsAndConditionsUrl={termsAndConditionsUrl}
              privacyPolicyUrl={privacyPolicyUrl}
            />
            <StartScreenWrapper>
              <LogoImage applyTheme={Start.Logo} src={require(asset`./images/Start/start-screen-logo@3x.png`)} />
              <MainContentContainer guestButton={isGuestLoginButton} theme={Start.MainContentContainer}>
                <MainContentHeader>{getText(TEXT_KEY.WELCOME_START)}</MainContentHeader>
                <SignInButtonWrapper className="splash-signup-button" onClick={signupHandler}>
                  <SignInButtonImage src={require(asset`./images/Start/Buttons/Guest_Button@3x.png`)} />
                </SignInButtonWrapper>
                {(hasFacebook || (state.playAsGuest === 'true' && isGuestLoginButton)) && (
                  <MainContentHeader>{getText(TEXT_KEY.OR)}</MainContentHeader>
                )}
                {hasFacebook && (
                  <SignInButtonWrapper className="splash-facebook-button" onClick={onFacebookActivate}>
                    <SignInButtonImage src={require(asset`./images/Start/Buttons/Facebook_Button@3x.png`)} />
                  </SignInButtonWrapper>
                )}
                {state.playAsGuest === 'true' && isGuestLoginButton && (
                  <GuestLoginButtonWrapper>
                    <Button
                      imageSource={require(asset`./images/Start/Buttons/login-guest-button.png`)}
                      onClick={onGuestActivate}
                      containerStyle={Styles.guestButtonStyle}
                      imageStyle={Styles.guestButtonStyle}
                      objectFit="contain"
                    />
                  </GuestLoginButtonWrapper>
                )}
              </MainContentContainer>
              <BottomBarComponent
                onAccountActivate={onAccountActivate}
                withGuest={state.playAsGuest === 'true'}
                isGuestLoginButton={isGuestLoginButton}
                onGuestActivate={onGuestActivate}
              />
            </StartScreenWrapper>
            {isLoading && !state.oauthLoading && (
              <Spinner style={{ position: 'absolute' }} animation="grow" className="custom-spinner" />
            )}
            {state.oauthLoading && !isLoading && (
              <Spinner style={{ position: 'absolute' }} animation="grow" className="custom-spinner" />
            )}
          </SplashScreen>
        );
      }}
    />
  );
};

DefaultStart.path = '';

export default DefaultStart;

