import React from 'react';
import Button from '../../../../components/Button/Button';
import { styles } from './styles';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import { getText, TEXT_KEY } from 'shared/utils/localization';

const DefaultFormButton = props => {
  const { handleLinkBtn, handleLinkAccount, showOTPConsents, error, activeOTPCheckbox } = props;

  let disableButton = showOTPConsents && !activeOTPCheckbox;

  return (
    <Button
      onClick={() => {
        if (!error) handleLinkBtn(handleLinkAccount);
      }}
      label={getText(TEXT_KEY.LINK)}
      isDebounce={true}
      disabled={disableButton}
      containerStyle={styles.buttonContainerStyle}
      imageSource={
        disableButton ? require(asset`RoundedRect_Disabled@2x.png`) : require(asset`RoundedRect_Primary@2x.png`)
      }
      textStroke={BUTTON_STROKES.PRIMARY}
    />
  );
};

export default DefaultFormButton;

