import styled from 'styled-components';
import theme from 'shared/assets/style/theme';

const headerBackground = require(asset`Full_Modal_Header@3x.png`);

export const HeaderContainer = styled.div`
  position: absolute;
  top: -1px;
  height: 3.7rem;
  width: 99%;
  justify-content: center;
  align-items: center;
  display: flex;
  border-top-right-radius: 20px;
  border-top-left-radius: 25px;
  background-size: calc(100% + 2px) 100%;
  background-image: url(${headerBackground});
`;

export const HeaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
`;

export const HeaderHeight = styled.div`
  height: 3.7rem;
`;

export const SubTitle = styled.span`
  font-size: 1.1em;
  margin-top: 0.5em;
  text-align: center;
  margin-bottom: 2em;
`;

export const DefaultHeaderContainer = styled.div`
  height: 30%;
  width: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DefaultHeaderTextWrapper = styled.span`
  text-align: center;
  font-size: 2em;
  display: block;
  margin-top: 1.2vmin;
  font-weight: 600;
  text-shadow: 2px 2px 4px ${theme.palette.common[0]};
`;

export const DefaultSubtitle = styled(SubTitle)`
  margin-top: -2.5em;
  margin-bottom: 0px;
`;

export const HighlightedHeaderContainer = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: -5px;
`;

