import generateRender from 'shared/utils/generateRender';
import DefaultLinkAccountHeader from './DefaultLinkAccountHeader';
import HighlightedHeader from '../../../../components/JSModal/components/HighlightedHeader';

const VipAccessHeader = generateRender({
  default: DefaultLinkAccountHeader,
  millelacs: HighlightedHeader,
});

export default VipAccessHeader;

