import React, { useState, useRef, useContext } from 'react';
import SLevelBar from 'shared/components/LevelBar';
import ImageBW from '../ImageBoundsWrapper';
import Tooltip from '../Tooltip';
import LevelUp from '../LevelUp';
import { TooltipTypes } from 'shared/utils/tooltipConstants';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { TEXT_KEY, getText } from 'shared/utils/localization';
import LottieAnimation from '../LottieAnimation/LottieAnimation';
import { ANIMATION_TYPE } from 'shared/components/LottieAnimation/constants';
import ThemeContext from 'shared/context/ThemeContext';
import Bar from '../Bar/Bar';

const LevelBar = props => {
  const { navigation, tooltipType, isCoinVisible, shouldShowCoinBalance } = props;

  const { NavigationBar } = useContext(ThemeContext);
  const [isGlowAnimationStopped, setIsGlowAnimationStopped] = useState(true);
  const percentRef = useRef(null);

  const expBarWidth = 1000;
  const shieldInset = 45 - 15;
  const percentInset = (shieldInset / expBarWidth) * 100 || 0;
  const percentString = percentInset + '%';

  const startGlowAnimation = () => {
    setIsGlowAnimationStopped(false);
    setTimeout(() => {
      setIsGlowAnimationStopped(true);
    }, [1850]);
  };

  const style = {
    content: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
    titleContainer: {
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: '0.03rem',
    },
    title: {
      fontSize: '1rem',
      fontWeight: '400',
    },
    divider: {
      width: '12rem',
      height: '0.2rem',
      zIndex: 5000,
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginTop: '0.03rem',
      width: '100%',
    },
    icon: {
      width: '1.5rem',
      height: '1.5rem',
      objectFit: 'contain',
      marginRight: '0.25rem',
    },
    amount: {
      fontSize: '1.4rem',
      fontWeight: '600',
    },
    maxBetImage: {
      width: '1.7rem',
      height: '1.7rem',
      objectFit: 'contain',
      marginRight: '0.1rem',
    },
    maxBet: {
      fontSize: '1.2rem',
      fontWeight: '400',
      marginLeft: '0.1rem',
    },
  };

  return (
    <SLevelBar
      {...props}
      navigation={navigation}
      minimumOutputPercent={percentString}
      render={({
        level,
        max,
        percent,
        showPercentage,
        nextLevelUpBonusCoins,
        nextLevelMaxBet,
        isLevellingUp,
        changeTooltipType,
      }) => {
        percentRef.current !== percent && startGlowAnimation();
        percentRef.current = percent;
        return (
          <div
            className="LevelBar"
            style={{
              flex: 1,
              position: 'relative',
              padding: 0,
              margin: 0,
              ...props.style,
            }}
            onMouseEnter={level < max ? changeTooltipType : null}
            onMouseLeave={changeTooltipType}
          >
            {!isCoinVisible && (
              <LottieAnimation
                animationType={ANIMATION_TYPE.XP_BAR_ANIMATION}
                animationStyle={NavigationBar.LevelBarAnimation}
                startDelay={2000}
                speed={0.5}
                manualIsStopped={isGlowAnimationStopped}
              />
            )}
            <Bar
              barIcon={require(asset`images/Top_Bar_Assets/EXP_Shield@3x.png`)}
              progress={percent.toFixed(0)}
              label={level >= max ? getText(TEXT_KEY.MAX) : level}
              percentageStyle={NavigationBar.LevelLabel}
              labelStyle={NavigationBar.LevelLabel}
              iconStyle={{ position: 'absolute', left: -20, ...(shouldShowCoinBalance && { height: 65, width: 65 }) }}
              showPercentage={showPercentage}
              showProgress
            />
            <Tooltip visible={tooltipType && tooltipType === TooltipTypes.XP}>
              <div style={style.content}>
                <div style={style.titleContainer}>
                  <span
                    className={`gradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                    style={style.title}
                    data-label="NEXT LEVEL-UP REWARD"
                  >
                    {getText(TEXT_KEY.NEXT_LEVEL)}
                  </span>
                  <ImageBW
                    style={style.divider}
                    source={require(asset`./images/Level_Up/Light_Divider@3x.png`)}
                    ibw={require(asset`./images/Level_Up/Light_Divider.ibw`)}
                    widthScale={0.5}
                  />
                </div>
                <div style={style.row}>
                  <div>
                    <ImageBW
                      source={require(asset`Coin_Icon@3x.png`)}
                      ibw={require(asset`./images/Level_Up/Icon.ibw`)}
                      style={style.icon}
                    />
                    <span
                      className={`gradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                      style={style.amount}
                      data-label={nextLevelUpBonusCoins}
                    >
                      {nextLevelUpBonusCoins}
                    </span>
                  </div>
                  <div>
                    <ImageBW
                      source={require(asset`./images/Level_Up/Max_Bet@3x.png`)}
                      ibw={require(asset`./images/Level_Up/Icon.ibw`)}
                      style={style.icon}
                    />
                    <span
                      className={`gradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                      style={style.amount}
                      data-label={nextLevelMaxBet}
                    >
                      {nextLevelMaxBet}
                    </span>
                  </div>
                </div>
              </div>
            </Tooltip>

            {isLevellingUp && <LevelUp navigation={navigation} />}
          </div>
        );
      }}
    />
  );
};

export default LevelBar;

