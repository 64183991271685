import generateRender from 'shared/utils/generateRender';
import DefaultVipHeader from './DefaultVipHeader';
import HighlightedHeader from '../../../../components/JSModal/components/HighlightedHeader';

const VipAccessHeader = generateRender({
  default: DefaultVipHeader,
  millelacs: HighlightedHeader,
});

export default VipAccessHeader;

