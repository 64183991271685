import React, { useContext } from 'react';
import ThemeContext from 'shared/context/ThemeContext';
import styled from 'styled-components';
import ImageBoundsWrapper from '../ImageBoundsWrapper';
import TimeRemainingText from '../TimeRemainingText';

const TimeRemaining = ({ timeRemaining }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <VIPTimer theme={themeContext.TimeRemaining.VIPTimer}>
      <TimerBackground
        source={require(asset`images/VIP_Assets/Glowing_Container@3x.png`)}
        ibw={require(asset`images/VIP_Assets/Glowing_Container.ibw`)}
      />
      <ClockIconGlowing src={require(asset`Clock_Icon_Glowing@3x.png`)} alt={'Clock Icon'} />
      <TimeRemainingText style={themeContext.TimeRemaining.TimerLabel} until={timeRemaining} />
    </VIPTimer>
  );
};

export default TimeRemaining;

export const VIPTimer = styled.div`
  position: absolute;
  z-index: 9;
  left: 0;
  top: 7rem;
  @media only screen and (max-width: 1600px) and (min-width: 1500px) and (max-height: 900px) and (min-height: 850px) {
    top: 5rem;
  }
  @media only screen and (max-height: 800px) {
    ${({ theme }) => theme}
  }
`;

export const TimerBackground = styled(ImageBoundsWrapper)`
  width: 12rem;
  height: 4rem;
`;

export const ClockIconGlowing = styled.img`
  position: absolute;
  top: 0.3rem;
  width: 3.5rem;
  height: 3.5rem;
  z-index: 9;
`;

