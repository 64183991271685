import React from 'react';
import Button from '../../../../components/Button/Button';
import { styles } from './styles';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import { getText, TEXT_KEY } from 'shared/utils/localization';

const MillelacsFormButton = props => {
  const { handleLinkBtn, handlePinPropertyAccount, error } = props;

  return (
    <Button
      onClick={() => {
        if (!error) handleLinkBtn(handlePinPropertyAccount);
      }}
      label={getText(TEXT_KEY.LINK)}
      containerStyle={styles.buttonContainerStyle}
      imageSource={require(asset`RoundedRect_Primary@3x.png`)}
      textStroke={BUTTON_STROKES.PRIMARY}
    />
  );
};

export default MillelacsFormButton;

