import React from 'react';
import { ACTION_COMMANDS_EVENT_TYPE, ACTION_COMMANDS_TYPE } from 'shared/api/ActionCommandsInterface/constants';
import { EVENT_EMITTER_TYPE } from 'shared/constants';
import Emitter from 'shared/services/EmitterService';
import { usePostLinkGuestAccountMutation } from 'shared/state/query/linkGuestAccount';
import { AUTH_TYPES } from 'shared/utils/authFlow/constants';
import { LinkImageButton } from '../../../../styledComponents';
import ModalContentWrapper from '../ModalContentWrapper';
import MemberLoginFormHandler from './MemberLoginFormHandler';
import useControlUpgradeModal from 'shared/screens/Settings/Account/hooks/useControlUpgradeModal';

export default function MemberLinkOption(props) {
  const { isActionCommand = false, modalType, onDisplayAction, memberLinkButtonStyles } = props;
  const [postLinkGuestAccount, result] = usePostLinkGuestAccountMutation();
  const { error, isLoading } = result;
  const { showModal, setShowModal } = useControlUpgradeModal({ queryResult: result });

  const openMemberLinkModal = () => {
    setShowModal(true);
    if (isActionCommand)
      Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
        actionType: ACTION_COMMANDS_TYPE.SUBMIT_EVENT_REQUEST,
        data: { event: ACTION_COMMANDS_EVENT_TYPE.SIGN_IN_EMAIL_APP_MODAL, modalType, onDisplayAction },
      });
  };

  const submitHandler = (email, password) => () => {
    postLinkGuestAccount({
      email,
      password,
      loginMethod: AUTH_TYPES.MEMBER,
    });
  };

  return (
    <>
      <div onClick={openMemberLinkModal}>
        <LinkImageButton
          src={require(asset`./images/Link_Guest_Account/continueWithMail.png`)}
          styled={memberLinkButtonStyles}
        />
      </div>
      <ModalContentWrapper isVisible={showModal} setModalState={setShowModal}>
        <MemberLoginFormHandler
          isLoading={isLoading}
          serverResponse={error?.data?.message || error?.data}
          submitHandler={submitHandler}
          queryResult={result}
        />
      </ModalContentWrapper>
    </>
  );
}

