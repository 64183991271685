import React, { useState, useEffect } from 'react';
import SAssetPreloader from 'shared/components/AssetPreloader';
import preload from '../../lib/load/preload';
import Utils from 'shared/utils/index';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { ASSET_PRELOADER_ERROR, BOOLEANS } from 'shared/constants';
import styled from 'styled-components';
import { useDispatch } from 'shared/node_modules/react-redux';
import { markAssetLoaded } from 'shared/state/actions/load';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import SplashScreenLoader from './components/SplashScreenLoader';
import SplashScreenFooter from './components/SplashScreenFooter';

let images = [];
if (ENVIRONMENT_VARIABLES.PRELOAD_ASSETS === BOOLEANS.TRUE) {
  try {
    images = require(`../../manifests/image.manifest.${process.env['APPLICATION_TARGET']}.js`);
  } catch (e) {
    throw new Error(ASSET_PRELOADER_ERROR);
  }
}

const HriPreloader = () => {
  const dispatch = useDispatch();
  const [showLogo, setShowLogo] = useState(true);

  const preloadAssets = async () => {
    if (images.length === 0) return;

    const promises = images.map(image =>
      preload(image.file).then(result =>
        dispatch(
          markAssetLoaded({
            name: image.file,
            status: result,
          })
        )
      )
    );

    await Promise.all(promises);
  };

  useEffect(() => {
    !showLogo && preloadAssets();
    const timer = setTimeout(() => {
      setShowLogo(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, [showLogo]);

  return (
    <SAssetPreloader
      images={images}
      render={({ percentLoaded }) => {
        return (
          <>
            <SplashScreenLoader showLogo={showLogo} />
            <div
              className="AssetPreloader"
              style={{
                backgroundImage: `url(${require(asset`images/AssetPreloader/loading_screen@3x.jpg`)})`,
              }}
            >
              <LoadingBarContainer className={Utils.ieVersion() !== 0 ? ' ieHack' : ''}>
                <LoadingBarBacking src={require(asset`images/AssetPreloader/loading-bar-backing.png`)} alt="" />
                <LoadingBarFillContainer>
                  <LoadingBarFillSide src={require(asset`images/AssetPreloader/loading-fill-side.png`)} alt="" />
                  <LoadingBarFillInner
                    src={require(asset`images/AssetPreloader/loading-fill-center.png`)}
                    percentLoaded={percentLoaded}
                    alt=""
                  />
                  <LoadingBarFillSide
                    src={require(asset`images/AssetPreloader/loading-fill-side.png`)}
                    transform
                    alt=""
                  />
                </LoadingBarFillContainer>
              </LoadingBarContainer>
              <Container>
                <Description>
                  {getText(TEXT_KEY.SPLASH_SCREEEN_PRELOADER_DESCRIPTION)
                    .split('/')
                    .map((text, index) => (
                      <TextColor key={index} color={index % 2 === 0 ? '#f3da57' : '#fffad4'}>
                        {text}
                      </TextColor>
                    ))}
                </Description>
              </Container>
            </div>
            <SplashScreenFooter />
          </>
        );
      }}
    />
  );
};

export default HriPreloader;

const LoadingBarContainer = styled.div`
  position: absolute;
  height: 7rem;
  width: 40rem;
  top: 38%;
  overflow: hidden;
  border-radius: 4em;
`;

const LoadingBarBacking = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
`;

const LoadingBarFillContainer = styled.div`
  display: flex;
  flex-direction: row;
  left: 0.25vw;
  position: absolute;
  height: 6.6rem;
  width: 98%;
  align-items: center;
`;

const LoadingBarFillSide = styled.img`
  height: 100%;
  transform: ${({ transform }) => (transform ? 'rotateY(180deg)' : 'none')};
`;

const LoadingBarFillInner = styled.img`
  height: 100%;
  width: ${({ percentLoaded }) => (percentLoaded > 3 ? percentLoaded - 10 : 3)}%;
  transition: all 1s ease;
  margin-inline: -0.8px;
`;

const Container = styled.div`
  margin-top: 15rem;
  width: 41rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  top: 0;
`;

const Description = styled.h4`
  font-size: 2.55rem;
  text-transform: uppercase;
  font-weight: 700;
  text-shadow: 0px 3px 1px #1d062b;
`;

const TextColor = styled.span`
  color: ${({ color }) => color};
`;

