import React from 'react';
import './../../index.css';
import './../../App.scss';
import {
  DefaultBackgroundImage,
  HeaderContainer,
  Message,
  ModalContainer,
  ModalContent,
  ModalMainBodyStyle,
  SocialModalStyle,
  Title,
  HeaderContnet,
  HeaderItem,
  NeedsHelpText,
} from './styledComponents';
import Modal from '../../components/JSModal';
import { onPressHelp } from 'shared/utils/redirectionHelp';
export default function GeoBlock({ status, message }) {
  return (
    <>
      <DefaultBackgroundImage alt="background" src={require(asset`images/Lobby_Assets/Background@3x.jpg`)} />
      <Modal
        customBackground
        hideCloseButton={true}
        hideOverlay={false}
        isVisible={status === 1}
        modalMainBodyStyle={ModalMainBodyStyle}
        onBackButtonPress={() => null}
        style={SocialModalStyle}
        zIndex={99999}
      >
        <ModalContainer>
          <HeaderContainer
            ibw={require(asset`Full_Modal_Header.ibw`)}
            position="absolute"
            source={require(asset`Full_Modal_Header.png`)}
          >
            <HeaderContnet>
              <HeaderItem />
              <HeaderItem />
              <HeaderItem justify={'flex-end'}>
                <NeedsHelpText onClick={onPressHelp}>Need Help?</NeedsHelpText>
              </HeaderItem>
            </HeaderContnet>
          </HeaderContainer>
          <ModalContent withButton={false}>
            <Message>
              {message || "We're sorry, this app is not available in your location due to geo-restriction."}
            </Message>
          </ModalContent>
        </ModalContainer>
      </Modal>
    </>
  );
}

