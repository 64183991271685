import React, { useEffect, useState } from 'react';
import useMissionsTutorial from 'shared/hooks/useMissionsTutorial';
import { MissionTutorialStepsKeys, tutorialPointerEvents, TUTORIAL_NAME } from 'shared/utils/tutorial';
import { Container, Layer, SkipButton, SkipButtonContainer, tutorialStepLayout } from './styledComponents';

const skipAsset = require(asset`images/FTUE/skip_button.png`);

const MissionsTutorialLayer = props => {
  const { tutorial, missionsAssets } = props;
  const { activeTutorial, tutorialStep, tutorialName } = tutorial;
  const { skipTutorial, handleFeedForwardTutorial } = useMissionsTutorial(props);

  const {
    IMAGE,
    TOOLTIP = null,
    TOP = null,
    BOTTOM = null,
    RIGHT = null,
    LEFT = null,
  } = tutorialStepLayout(missionsAssets)[tutorialStep];

  const [renderImage, setImageRender] = useState(IMAGE);

  useEffect(() => {
    setImageRender(IMAGE);
  }, [IMAGE]);

  return (
    <Container activeTutorial={activeTutorial && tutorialName === TUTORIAL_NAME.MISSIONS_TUTORIAL}>
      {tutorialStep !== MissionTutorialStepsKeys.COMPLETED && (
        <SkipButtonContainer onClick={skipTutorial}>
          <SkipButton src={skipAsset} />
        </SkipButtonContainer>
      )}

      <Layer
        pointerEvents={tutorialPointerEvents(tutorialStep === MissionTutorialStepsKeys.CHECK_OUT)}
        onClick={handleFeedForwardTutorial}
      >
        {IMAGE === renderImage && <TOOLTIP src={IMAGE} />}
        {TOP && <TOP />}
        {BOTTOM && <BOTTOM />}
        {RIGHT && <RIGHT />}
        {LEFT && <LEFT />}
      </Layer>
    </Container>
  );
};

export default MissionsTutorialLayer;

