import LobbyOverlay from './LobbyOverlay';
import { connect } from 'shared/node_modules/react-redux';
import { FE_ANIMATION } from 'shared/utils/featureFlags/constants';
import { currencyDisplayComponentName } from '../CurrencyDisplay/CurrencyDisplay';
import { chestComponentName } from '../BottomBar/BottomBar';
import { MissionTutorialStepsKeys, TUTORIAL_NAME } from 'shared/utils/tutorial';

const mapStateToProps = state => {
  return {
    blockLobby: state.tutorial.blockLobby,
    lobbyPromotions: state.promotions.lobbyNews,
    isMissionsTutorialFirstStep:
      state.tutorial.activeTutorial &&
      state.tutorial.tutorialName === TUTORIAL_NAME.MISSIONS_TUTORIAL &&
      state.tutorial.tutorialStep === MissionTutorialStepsKeys.CHECK_OUT,
    animationFeature: state.featureFlags.enabledFeatures[FE_ANIMATION]?.enabled,
    currencyDisplayXPosition: state.layout[currencyDisplayComponentName]?.x,
    currencyDisplayYPosition: state.layout[currencyDisplayComponentName]?.y,
    currencyDisplayWidth: state.layout[currencyDisplayComponentName]?.width,
    currencyDisplayHeight: state.layout[currencyDisplayComponentName]?.height,
    chestPosition: state.layout[chestComponentName]?.x,
    chestWidth: state.layout[chestComponentName]?.width,
    chestHeight: state.layout[chestComponentName]?.height,
  };
};

export default connect(mapStateToProps, null)(LobbyOverlay);

