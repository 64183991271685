import React from 'react';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import Utils from 'shared/utils/index';
import ImageBW from '../../../../components/ImageBoundsWrapper';

const DefaultHeader = ({ title }) => {
  return (
    <>
      <span
        style={styles.titleWrapper}
        className={`watchAndEarnGradientText warningHeader ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
        data-label={title}
      >
        {title}
      </span>
      <ImageBW
        source={require(asset`Shop_VIP_Divider_Glows@3x.png`)}
        ibw={require(asset`Shop_VIP_Divider_Glows.ibw`)}
        manualAssetScale={3}
        style={styles.divider}
        innerStyle={styles.dividerInner}
      ></ImageBW>
    </>
  );
};

export default DefaultHeader;

const styles = {
  divider: {
    height: 40,
    width: '100%',
  },
  dividerInner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingLeft: '1vmin',
    paddingRight: '1vmin',
  },
  titleWrapper: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: Utils.ieVersion() !== 0 ? 0 : 14,
    fontWeight: 600,
    fontSize: 25,
  },
};

