import CurrencySelector from './CurrencySelector';
import { connect } from '../../node_modules/react-redux';
var mapStateToProps = function mapStateToProps(state) {
  return {
    shopList: state.shopList,
    outOfCoinsOffer: state.consumables.outOfCoins,
    outOfCoinsPurchase: state.prePurchase.outOfCoinsPurchase,
    casinoIdStatus: state.playerInfo.casinoIdStatus,
    ltoPurchase: state.prePurchase.ltoPurchase,
    limitedTimeOffer: state.consumables.ltoOffer
  };
};
export default connect(mapStateToProps, null)(CurrencySelector);