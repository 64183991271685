import React, { useContext } from 'react';
import ControlHeader from '../ControlHeader';
import { REWARD_CENTER_TABS } from 'shared/screens/RewardCenter/constants';
import ThemeContext from 'shared/context/ThemeContext';
import { RewardTabContent } from '../../constants';
import useRewardCenterProfile from 'shared/screens/RewardCenter/hooks/useRewardCenterProfile';
import ActivityIndicator from '../../../../components/ActivityIndicator/ActivityIndicator';
import Modal from '../../../../components/JSModal';
import theme from 'shared/assets/style/theme';
import {
  ActivityIndicatorContainer,
  MainContainer,
  ModalMainBodyStyle,
  ModalStyles,
  RewardCenterMainContentHolder,
} from './styledComponent';

const RewardCenterProfile = props => {
  const themeContext = useContext(ThemeContext);
  const { profile, showProfile, setShowProfile, isInitRewardsLoading } = props;
  const { displayRewardProfileScreen, displayRewardHistoryScreen, displayRewardLoyaltyScreen } = profile ?? {};

  const rewardCenterProfileHookData = useRewardCenterProfile(props);
  const { interfaceData, setActiveTab } = rewardCenterProfileHookData;

  let activeTab = interfaceData?.activeTab;
  if (!activeTab) {
    if (displayRewardProfileScreen) {
      activeTab = REWARD_CENTER_TABS.PROFILE;
    } else if (displayRewardHistoryScreen) {
      activeTab = REWARD_CENTER_TABS.HISTORY;
    } else if (displayRewardLoyaltyScreen) {
      activeTab = REWARD_CENTER_TABS.LOYALTY_POINTS;
    }
  }

  const RewardTab = RewardTabContent[activeTab] || RewardTabContent[REWARD_CENTER_TABS.PROFILE];

  const handleCloseModal = () => {
    setShowProfile(false);
    // Since the modal closes by sliding to the bottom,
    // this timeout prevents the user from seeing the
    // tab change before it closes.
    setTimeout(() => {
      setActiveTab(null);
    }, 800);
  };

  return (
    <Modal
      modalMainBodyStyle={ModalMainBodyStyle}
      isVisible={showProfile}
      onBackButtonPress={handleCloseModal}
      zIndex={101}
      style={ModalStyles}
      dialogClassName={'gtp-modal-reward-center'}
    >
      <MainContainer>
        <div className="RewardCenter">
          {isInitRewardsLoading ? (
            <ActivityIndicatorContainer>
              <ActivityIndicator color={`${theme.palette.primary[9]}`} style={{ flex: 1 }} />
            </ActivityIndicatorContainer>
          ) : (
            <>
              <ControlHeader {...props} {...rewardCenterProfileHookData} activeTab={activeTab} />
              <RewardCenterMainContentHolder
                theme={themeContext.RewardCenter.RewardCenterMainContentHolder}
                tab={activeTab}
              >
                <RewardTab {...props} {...rewardCenterProfileHookData} {...profile} />
              </RewardCenterMainContentHolder>
            </>
          )}
        </div>
      </MainContainer>
    </Modal>
  );
};

export default RewardCenterProfile;

