import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/services/rtkQuery/axiosBaseQuery';
import { postLoginMutation, postSSOLoginMutation, postGuestLoginMutation, postCheckIfEmailExistsMutation, postSsoUpgradeMutation, postSsoInitLinkLoyaltyCardQuery, postSsoLinkLoyaltyCardMutation, getWebTrackingUrlDataQuery } from './queries';
export var loginApi = createApi({
  reducerPath: 'loginQuery',
  baseQuery: axiosBaseQuery(),
  keepUnusedDataFor: 10,
  tagTypes: ['Login'],
  endpoints: function endpoints(builder) {
    return {
      postLogin: builder.mutation(postLoginMutation),
      postSSOLogin: builder.mutation(postSSOLoginMutation),
      postGuestLogin: builder.mutation(postGuestLoginMutation),
      postCheckIfEmailExists: builder.mutation(postCheckIfEmailExistsMutation),
      postSsoUpgrade: builder.mutation(postSsoUpgradeMutation),
      postSsoInitLinkLoyaltyCard: builder.query(postSsoInitLinkLoyaltyCardQuery),
      postSsoLinkLoyaltyCard: builder.mutation(postSsoLinkLoyaltyCardMutation),
      getWebTrackingUrlData: builder.query(getWebTrackingUrlDataQuery)
    };
  }
});
var usePostLoginMutation = loginApi.usePostLoginMutation,
  usePostSSOLoginMutation = loginApi.usePostSSOLoginMutation,
  usePostGuestLoginMutation = loginApi.usePostGuestLoginMutation,
  usePostCheckIfEmailExistsMutation = loginApi.usePostCheckIfEmailExistsMutation,
  usePostSsoUpgradeMutation = loginApi.usePostSsoUpgradeMutation,
  usePostSsoInitLinkLoyaltyCardQuery = loginApi.usePostSsoInitLinkLoyaltyCardQuery,
  usePostSsoLinkLoyaltyCardMutation = loginApi.usePostSsoLinkLoyaltyCardMutation,
  useGetWebTrackingUrlDataQuery = loginApi.useGetWebTrackingUrlDataQuery;
export { usePostLoginMutation, usePostSSOLoginMutation, usePostGuestLoginMutation, usePostCheckIfEmailExistsMutation, usePostSsoUpgradeMutation, usePostSsoInitLinkLoyaltyCardQuery, usePostSsoLinkLoyaltyCardMutation, useGetWebTrackingUrlDataQuery };
var _loginApi$endpoints = loginApi.endpoints,
  postLogin = _loginApi$endpoints.postLogin,
  postGuestLogin = _loginApi$endpoints.postGuestLogin,
  postSSOLogin = _loginApi$endpoints.postSSOLogin,
  loginReducerPath = loginApi.reducerPath,
  loginQueryReducer = loginApi.reducer,
  loginMiddleware = loginApi.middleware;
export { loginReducerPath, loginQueryReducer, loginMiddleware, postLogin, postGuestLogin, postSSOLogin };