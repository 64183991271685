import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/services/rtkQuery/axiosBaseQuery';
import { postRegisterNewCardMutation } from './queries';
export var registerNewCardApi = createApi({
  reducerPath: 'registerNewCardQuery',
  baseQuery: axiosBaseQuery(),
  endpoints: function endpoints(builder) {
    return {
      postRegisterNewCard: builder.mutation(postRegisterNewCardMutation)
    };
  }
});
var usePostRegisterNewCardMutation = registerNewCardApi.usePostRegisterNewCardMutation;
export { usePostRegisterNewCardMutation };
var postRegisterNewCard = registerNewCardApi.endpoints.postRegisterNewCard,
  registerNewCardReducerPath = registerNewCardApi.reducerPath,
  registerNewCardQueryReducer = registerNewCardApi.reducer,
  registerNewCardMiddleware = registerNewCardApi.middleware;
export { registerNewCardReducerPath, registerNewCardQueryReducer, registerNewCardMiddleware, postRegisterNewCard };