import styled from 'styled-components';

const ModalStyles = {
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  padding: '0 0',
  width: '100%',
};

const ModalMainBodyStyle = {
  width: '80%',
  maxWidth: '1100px',
  maxHeight: '75vh',
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const RewardCenterMainContentHolder = styled.div`
  ${({ theme }) => theme}
  height: 545px;

  @media only screen and (max-width: 1500px) {
    height: 545px;
    max-height: 55vh;
  }
`;

const ActivityIndicatorContainer = styled.div`
  height: 65vh;
  max-height: 630px;
`;

export { ModalStyles, ModalMainBodyStyle, MainContainer, RewardCenterMainContentHolder, ActivityIndicatorContainer };

