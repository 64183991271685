/** */
import ENVIRONMENT_VARIABLES from '../environmentVariables';
var getUrl = function getUrl() {
  return "https://".concat(ENVIRONMENT_VARIABLES.CDN_ROOT);
};
var getCdnPath = function getCdnPath() {
  var CDN_ROOT = getUrl();
  return "".concat(CDN_ROOT, "/app/images");
};
var PREFIX = function PREFIX() {
  return getCdnPath();
};
export default {
  LOBBY_ADVERTS: '/promotions/',
  COIN_PACKAGE: "".concat(PREFIX(), "/marketing/coinpkg/"),
  SLOT_ICON_WEB: "".concat(PREFIX(), "/games/1x1/"),
  SLOT_ICON_MOBILE: "".concat(PREFIX(), "/games/1x1/"),
  REWARD_CENTER_OFFERS_UI: "".concat(PREFIX(), "/offers/ui/")
};