import styled from 'styled-components';
import { isTi } from 'shared/utils/targetApplication';

const BlackOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${({ isVisible }) => (isVisible ? 'rgba(0,0,0,0.5)' : 'transparent')};
  z-index: 201;
  pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
  transition: background-color 0.3s ease;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  z-index: 390;
  left: ${({ menuWidth }) => -menuWidth}px;
  transform: ${({ isMenuVisible, menuWidth }) => (isMenuVisible ? `translateX(${menuWidth}px)` : 'translateX(0)')};
  transition: transform 0.3s ease-in-out;
  align-items: center;
  height: 100%;
`;

const MenuContainer = styled.div`
  display: inline;
  z-index: 390;
  box-sizing: border-box;
  width: 31vw;
  margin-left: -1vh;
  min-width: 445px;
  justify-content: space-around;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  box-shadow: inset 0px 0 40px #000000;
  ${({ theme }) => theme};
  ${({ hasSportsRoom }) => !hasSportsRoom && isTi && `height: 75vh`};
`;

const MenuItemContainer = styled.div`
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.4 : 1)};
  padding: 0 0 0 4rem;
  display: flex;
  flex: 1;
  align-items: center;
`;

const MenuItemText = styled.h1`
  position: relative;
  display: inline;
  font-weight: 600;
  line-height: 5vh;
  text-align: left;

  @media only screen and (max-width: 1280px) {
    font-size: 3.3vw;
  }

  span {
    position: relative;
    background: linear-gradient(0deg, #e7c377 0%, #e7c377 35%, #a1743d 50%, #fef7b9 51%, #e7c377 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-stroke: 1px #ffff8d;
    filter: saturate(160%);
  }

  &::before {
    content: attr(data-text);
    position: absolute;
    top: 1px;
    left: 0;
    z-index: -1;
    -webkit-text-stroke: 8px #000000;
    opacity: 0.5;
  }

  &::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    color: rgba(0, 0, 0, 0.5);
    text-shadow: 0px 10px 15px rgba(0, 0, 0, 0.5);
    -webkit-text-stroke: 5px #4c300b;
  }

  &::after {
    color: transparent;
  }

  ${({ theme }) => theme};
`;

const ItemButton = styled.img.attrs(({ isActive }) => ({
  src: isActive
    ? require(asset`images/SideBar_Assets/sidebar-button-highlighted.png`)
    : require(asset`images/SideBar_Assets/sidebar-button.png`),
}))`
  ${({ theme }) => theme};
`;

const Toggler = styled.img.attrs(() => ({
  src: require(asset`images/SideBar_Assets/sidebar-arrow-button.png`),
}))`
  height: 6vw;
  margin-left: ${({ isMenuVisible }) => (isMenuVisible ? '10px' : '2.5vw')};
  cursor: pointer;
  transition: 0.3s ease-in-out;
  transform: ${({ isMenuVisible }) => (isMenuVisible ? 'rotate(180deg)' : 'rotate(0)')};
`;

export { BlackOverlay, Container, MenuContainer, MenuItemContainer, MenuItemText, ItemButton, Toggler };

