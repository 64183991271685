import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import generateRender from 'shared/utils/generateRender';
import theme from 'shared/assets/style/theme';

const TextInputDefault = styled(Form.Control).attrs({
  className: 'transparentInput',
})``;

const TextInputMillelacs = styled(Form.Control)`
  background: rgb(0 39 62);
  border-radius: 0.6em;
  border: none;
  height: 42px;
  color: ${theme.palette.common[1]};
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${theme.palette.common[1]};
  }
  &:focus {
    background: rgb(0 39 62);
    border-radius: 0.6em;
    border: none;
    color: ${theme.palette.common[1]};
  }
`;

const TextInput = generateRender({
  default: TextInputDefault,
  millelacs: TextInputMillelacs,
});

export default TextInput;

