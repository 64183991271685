import React from 'react';
import ImageBW from '../../../../../../components/ImageBoundsWrapper';

const ProfileCardFrame = ({ children, isLinked, frameAsset }) => {
  const containerClass = isLinked ? 'RewardCenterProfileDetailBox' : 'RewardCenterUnlinkedProfileDetailBox';
  const innerStyle = isLinked ? { justifyContent: 'space-around' } : null;

  return (
    <ImageBW
      source={frameAsset}
      ibw={require(asset`images/Rewards_Center_Assets/section.ibw`)}
      className={containerClass}
      innerClassName="RewardCenterProfileDetailBoxInner"
      innerStyle={innerStyle}
    >
      {children}
    </ImageBW>
  );
};

export default ProfileCardFrame;

