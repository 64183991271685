import React from 'react';
import styled from 'styled-components';
import EntriesCounter from './Counter';
import CustomScrollBar from '../../../../components/CustomScrollBar';
import { TEXT_KEY, getText } from 'shared/utils/localization';

const EventsCardDetail = ({ renderElements, item, openCardDetail, setOpenCardDetail, eventsAssets }) => {
  const { BackgroundDetail } = renderElements;
  const { cardDetailsIntro, cardDetailsTitle, cardDetailsDescription, rulesLink } = item.texts;
  const { url: rulesLinkUrl, text: rulesLinkText } = rulesLink;

  const onClickURL = url => {
    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }
  };

  return (
    <Wrapper openCardDetail={openCardDetail}>
      <ContainerDetail openCardDetail={openCardDetail}>
        <Background main={item?.isMainEvent} src={BackgroundDetail} />
        <TouchableCloseButton>
          <CloseButton
            onClick={() => setOpenCardDetail(false)}
            openCardDetail={openCardDetail}
            src={eventsAssets.EVENTS_DETAIL_CLOSE_BUTTON}
            alt={getText(TEXT_KEY.CLOSE_VIDEO_MODAL)}
          />
        </TouchableCloseButton>
        <Container openCardDetail={openCardDetail}>
          <Header main={item?.isMainEvent}>
            <Intro length={cardDetailsIntro?.length}>{cardDetailsIntro}</Intro>
            <Title>{cardDetailsTitle}</Title>
          </Header>
          <Body>
            <CustomScrollBar
              showByDefault={openCardDetail}
              timeout={700}
              customHeight={window.innerHeight > 800 ? '75vh' : '79vh'}
            >
              <ScrollContent>
                <BodyText>
                  {cardDetailsDescription}
                  {!!rulesLinkUrl && ' ' + rulesLinkText.trim() + ' '}
                  {!!rulesLinkUrl && (
                    <RulesLinkText onClick={() => onClickURL(rulesLinkUrl)}>
                      {getText(TEXT_KEY.EVENTS_HERE_LABEL)}
                    </RulesLinkText>
                  )}
                </BodyText>
              </ScrollContent>
            </CustomScrollBar>
          </Body>
          <Footer>
            <EntriesCounter renderElements={renderElements} detailTexts={item.texts} item={item} />
            <LogoWrapper>
              <Logo src={eventsAssets.EVENTS_DETAIL_CARD_LOGO} />
            </LogoWrapper>
          </Footer>
        </Container>
      </ContainerDetail>
    </Wrapper>
  );
};

export default EventsCardDetail;

const Wrapper = styled.div`
  position: relative;
  height: 55vh;
  width: ${({ openCardDetail }) => (openCardDetail ? '72vh' : '0')};
  left: 32vh;
  top: -65.7vh;
  display: flex;
  justify-content: center;
  z-index: 0;
  transition: 0.7s ease all;
  @media only screen and (min-height: 1080px) {
    left: 34vh;
  }
`;

const ContainerDetail = styled.div`
  height: 95%;
`;

const Background = styled.img`
  width: 100%;
  height: 100%;

  @media only screen and (max-height: 700px) {
    margin-top: ${({ main }) => (main ? 0 : 2)}px;
    height: 99%;
  }
`;

const TouchableCloseButton = styled.div`
  position: absolute;
  right: -2rem;
  top: -1rem;
  cursor: pointer;
  height: 4rem;
`;

const CloseButton = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: ${({ openCardDetail }) => (openCardDetail ? '1' : '0')};
  transition: 0.7s ease all;
`;

const Container = styled.div`
  height: 90%;
  width: 95%;
  position: absolute;
  top: 1rem;
  opacity: ${({ openCardDetail }) => (openCardDetail ? '1' : '0')};
  transition: opacity 0.3s ease ${({ openCardDetail }) => (openCardDetail ? '0.6s' : '0s')};
  @media only screen and (max-height: 750px) {
    top: 0.5rem;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 6.5rem;
  padding-top: 2rem;

  @media only screen and (max-height: 750px) {
    padding-left: 3.5rem;
  }
  @media only screen and (max-height: 600px) {
    padding-left: ${({ main }) => (main ? 3.5 : 2)}rem;
  }
`;

const Intro = styled.span`
  font-size: ${({ length }) => (length > 30 ? 1.2 : 1.4)}rem;
  color: #fff;
  text-shadow: 1px 3px 4px rgba(0, 0, 0, 0.8);
  font-weight: bold;
  margin: 0;
  text-transform: capitalize;
  @media only screen and (max-height: 600px) {
    font-size: 1.1rem;
  }
`;

const Title = styled.span`
  font-size: 1.8rem;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 1px 5px 10px rgba(0, 0, 0, 0.8);
  font-weight: 800;
  margin: 0;

  @media only screen and (max-height: 800px) {
    font-size: 1.55rem;
  }

  @media only screen and (max-height: 600px) {
    font-size: 1.4rem;
  }

  @media only screen and (max-height: 500px) {
    font-size: 1.2rem;
  }
`;

const ScrollContent = styled.div`
  height: 100%;
`;

const Body = styled.div`
  width: 100%;
  padding: 2rem 0 0 6.5rem;
  height: 55%;
  @media only screen and (max-height: 750px) {
    padding: 2rem 0 0 3.5rem;
  }
  @media only screen and (max-height: 600px) {
    padding: 2rem 0 0 2rem;
  }
`;

const BodyText = styled.p`
  font-size: 1.2rem;
  color: #fff;
  font-weight: normal;
  white-space: pre-wrap;
  padding-right: 1rem;
  text-align: left;
  margin: 0;
  margin-right: 1rem;
  text-shadow: 1px 3px 4px rgba(0, 0, 0, 0.8);
  @media only screen and (max-height: 600px) {
    font-size: 1rem;
  }
`;

const RulesLinkText = styled.p`
  font-size: 1.2rem;
  text-decoration: underline;
  color: #fff;
  font-weight: normal;
  display: inline;
  cursor: pointer;
`;

const Footer = styled.div`
  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: row;
  padding: 1rem 0 0 6rem;
  @media only screen and (max-height: 750px) {
    padding: 0.2rem 0 0 3rem;
  }
  @media only screen and (max-height: 600px) {
    padding: 0.2rem 0 0 2rem;
  }
`;

const LogoWrapper = styled.div`
  height: 100%;
  width: 30%;
  align-items: flex-end;
  padding-right: 2%;
  padding-top: 1.5rem;
  @media only screen and (max-height: 600px) {
    padding-top: 1rem;
  }
`;

const Logo = styled.img`
  width: 80%
  height: 100%;
`;

