import React from 'react';
import styled from 'styled-components';

const SplashScreenLoader = ({ showLogo }) => {
  return (
    <Container isVisible={showLogo}>
      <LoadingImage alt="loading-logo" src={require(asset`images/AssetPreloader/loading-logo.png`)} />
    </Container>
  );
};

export default SplashScreenLoader;

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: black;
  align-items: center;
  justify-content: center;
  display: flex;
  transition: opacity 1s linear;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`;

const LoadingImage = styled.img`
  width: 30%;
  height: 30%;
  object-fit: contain;
`;
