import { useCallback, useEffect, useRef, useState } from 'react';

function useCustomScrollBar({
  showByDefault,
  timeout,
  showVertical,
  showHorizontal,
  customScrollBarXHeight = 10,
  arrowClickScrollAmount = 10,
  scrollToPosition,
}) {
  const scrollBoxRef = useRef();
  const [scrollBarWidth, changeScrollBarWidth] = useState(0);
  const [scrollBarXHeight, changeScrollBarXHeight] = useState(0);

  const onScroll = () => {
    if (scrollBarWidth === 0 && showVertical) changeScrollBarWidth(0.5);
    if (scrollBarXHeight === 0 && showHorizontal) changeScrollBarXHeight(customScrollBarXHeight);
  };

  const checkToShowScrollBar = useCallback(() => {
    if (showByDefault) {
      setTimeout(() => {
        const scrollHeight = scrollBoxRef.current?.getScrollHeight();
        const clientHeight = scrollBoxRef.current?.getClientHeight();
        const scrollWidth = scrollBoxRef.current?.getScrollWidth();
        const clientWidth = scrollBoxRef.current?.getClientWidth();
        if (scrollHeight > clientHeight && scrollBarWidth === 0 && showVertical) changeScrollBarWidth(0.5);
        if (scrollWidth - clientWidth > 50 && scrollBarXHeight === 0 && showHorizontal)
          changeScrollBarXHeight(customScrollBarXHeight);
      }, [timeout]);
    }
  }, [showByDefault, timeout]);

  const scrollTo = (position = 0, isVertical = true) => {
    if (scrollBoxRef.current) {
      if (isVertical) {
        scrollBoxRef.current.scrollTop(position);
      } else {
        scrollBoxRef.current.scrollLeft(position);
      }
    }
  };

  const smoothScroll = (element, target, duration = 250) => {
    let start = element.scrollLeft;
    let change = target - start;
    let startTime = null;

    const animateScroll = currentTime => {
      if (!startTime) startTime = currentTime;
      let timeElapsed = currentTime - startTime;
      let progress = Math.min(timeElapsed / duration, 1);

      element.scrollLeft = start + change * progress;

      if (timeElapsed < duration) {
        window.requestAnimationFrame && window.requestAnimationFrame(animateScroll);
      }
    };

    window.requestAnimationFrame && window.requestAnimationFrame(animateScroll);
  };

  const handleArrowScrollClick = useCallback(
    direction => {
      const scrollDelta = direction === 'left' ? -arrowClickScrollAmount : arrowClickScrollAmount;
      if (scrollBoxRef.current) {
        let newScrollPosition = scrollBoxRef.current.getScrollLeft() + scrollDelta;
        smoothScroll(scrollBoxRef.current.view, newScrollPosition);
      }
    },
    [arrowClickScrollAmount, smoothScroll]
  );

  useEffect(() => {
    checkToShowScrollBar();
  }, [checkToShowScrollBar]);

  useEffect(() => {
    scrollToPosition !== null && scrollTo(scrollToPosition, showVertical);
  }, [scrollToPosition]);

  return {
    scrollBoxRef,
    scrollBarWidth,
    scrollBarXHeight,
    onScroll,
    scrollTo,
    smoothScroll,
    handleArrowScrollClick,
  };
}

export default useCustomScrollBar;

