import React from 'react';
import debounce from 'lodash/debounce';
import styled from 'styled-components';
import theme from 'shared/assets/style/theme';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';

const Button = props => {
  const {
    containerStyle = {},
    imageStyle = {},
    label,
    imageSource,
    onClick,
    objectFit,
    textStyle,
    disabled,
    isDebounce,
    textStroke,
    buttonIcon,
    buttonIconStyle,
    buttonWrapperStyle,
    children,
  } = props;

  const onClickHandler = debounce(() => onClick(), isDebounce ? 500 : 0, { leading: true, trailing: false });

  const strokeColor = disabled ? BUTTON_STROKES.DISABLED : textStroke || BUTTON_STROKES.DISABLED;

  return (
    <ButtonWrapper
      onClick={e => {
        e.stopPropagation();
        return !disabled ? onClickHandler() : null;
      }}
      containerStyle={containerStyle}
    >
      <ButtonImage imageSource={imageSource} imageStyle={imageStyle} objectFit={objectFit} />
      <ButtonContentWrapper style={buttonWrapperStyle} hasChildren={!!children}>
        {buttonIcon && <ButtonIcon src={buttonIcon} buttonIconStyle={buttonIconStyle} />}
        <ButtonText textStroke={strokeColor} textStyle={textStyle}>
          {label}
        </ButtonText>
        {children}
      </ButtonContentWrapper>
    </ButtonWrapper>
  );
};

export default Button;

const ButtonWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 180px;
  text-align: center;
  cursor: pointer;
  ${({ containerStyle }) => containerStyle}
`;

const ButtonImage = styled.img.attrs(({ imageSource }) => ({
  src: imageSource,
}))`
  position: absolute;
  width: 180px;
  object-fit: ${({ objectFit }) => objectFit || 'contain'};
  z-index: 1;
  ${({ imageStyle }) => imageStyle}
`;

const ButtonContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: ${({ hasChildren }) => (hasChildren ? 'column' : 'row')};
`;

const ButtonIcon = styled.img`
  ${({ buttonIconStyle }) => buttonIconStyle};
  z-index: 1;
`;

const ButtonText = styled.p`
  font-size: 24px;
  font-weight: 700;
  margin: 0 auto !important;
  z-index: 2;
  color: ${theme.palette.common[1]};
  text-stroke: 1px ${({ textStroke }) => textStroke};
  -webkit-text-stroke: 1px ${({ textStroke }) => textStroke};
  padding-bottom: 3%;
  ${({ textStyle }) => textStyle}
`;

