import React, { useState } from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import OTPInput from 'react-otp-input';
import {
  ButtonImage,
  ButtonText,
  Message,
  Button,
  ModalContainer,
  ModalContent,
  Title,
  TitleContainer,
  OPTInputStyle,
  HeaderContainer,
  ActionsContainer,
} from './styledComponents';
import useOTPrequestHandler from 'shared/screens/OneTimePassword/hooks/useOTPrequestHandler';
import QueryParamProxy from 'shared/utils/queryParamProxy';
import routeParamConstants from 'shared/utils/routeParamConstants';
import debounce from 'lodash/debounce';

const OneTimePassword = () => {
  OneTimePassword.DismissHandler = () => {
    QueryParamProxy.setQueryParams({
      [routeParamConstants.PARAM_MODAL]: undefined,
    });
    return { ...requestResponse, OTPValue };
  };

  const { handleResend, handleSubmit, requestResponse, isSendingRequest } = useOTPrequestHandler(OneTimePassword);
  const [OTPValue, setOTPValue] = useState('');
  const enableSubmitButton = OTPValue && OTPValue.length === 6 && !isSendingRequest;

  return (
    <ModalContainer>
      <HeaderContainer
        ibw={require(asset`Full_Modal_Header.ibw`)}
        source={require(asset`Full_Modal_Header.png`)}
        position="absolute"
      >
        <TitleContainer>
          <Title>{getText(TEXT_KEY.ONE_TIME_PASSWORD_TITLE)}</Title>
        </TitleContainer>
      </HeaderContainer>

      <ModalContent>
        <Message status={requestResponse.success}>
          {isSendingRequest
            ? getText(TEXT_KEY.ONE_TIME_PASSWORD_SENDING_REQUEST)
            : requestResponse.message
              ? requestResponse.message
              : getText(TEXT_KEY.ONE_TIME_PASSWORD_MESSAGE)}
        </Message>
        <OTPInput onChange={value => setOTPValue(value)} value={OTPValue} inputStyle={OPTInputStyle} numInputs={6} />
        <ActionsContainer>
          <Button
            onClick={debounce(
              () => {
                if (!isSendingRequest) handleResend();
              },
              1500,
              { leading: true, trailing: false }
            )}
          >
            <ButtonImage
              src={
                !isSendingRequest
                  ? require(asset`RoundedRect_Primary@3x.png`)
                  : require(asset`RoundedRect_Disabled@3x.png`)
              }
            />
            <ButtonText>{getText(TEXT_KEY.ONE_TIME_PASSWORD_BUTTON_RESEND)}</ButtonText>
          </Button>
          <Button
            onClick={debounce(
              () => {
                if (enableSubmitButton) handleSubmit(OTPValue);
              },
              1500,
              { leading: true, trailing: false }
            )}
          >
            <ButtonImage
              src={
                enableSubmitButton
                  ? require(asset`RoundedRect_Primary@3x.png`)
                  : require(asset`RoundedRect_Disabled@3x.png`)
              }
            />
            <ButtonText>{getText(TEXT_KEY.ONE_TIME_PASSWORD_BUTTON_SUBMIT)}</ButtonText>
          </Button>
        </ActionsContainer>
      </ModalContent>
    </ModalContainer>
  );
};
export default OneTimePassword;

