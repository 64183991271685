import React from 'react';
import { HeaderContainer, HeaderWrapper, HeaderHeight, HighlightedHeaderContainer } from '../styledComponents';

const HighlightedHeader = ({ title }) => {
  return (
    <>
      <HeaderWrapper>
        <HeaderContainer>
          <HighlightedHeaderContainer>{title}</HighlightedHeaderContainer>
        </HeaderContainer>
      </HeaderWrapper>
      <HeaderHeight />
    </>
  );
};

export default HighlightedHeader;

