import React, { Component } from 'react';
import Animated from 'animated/lib/targets/react-dom';

import ImageBW from '../../components/ImageBoundsWrapper';
import theme from 'shared/assets/star/style/theme';
import ThemeContext from 'shared/context/ThemeContext';
import styled from 'styled-components';

export default class JSModal extends Component {
  state = {
    visible: false,
    renderChildren: false,
    slideInValue: new Animated.Value(0),
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isVisible !== this.props.isVisible) {
      this.setState({
        visible: this.props.isVisible,
      });
    }

    // visiblity change
    if (prevState.visible !== this.state.visible) {
      // stop all running animation
      this.state.slideInValue.stopAnimation();
      // If we should be visible
      if (this.state.visible) {
        // Turn on render
        this.setState({ renderChildren: true });
        // Slide in
        Animated.timing(this.state.slideInValue, {
          toValue: 1,
          duration: 400,
          useNativeDriver: true,
        }).start();
      }
      // If we shouldn't be visible
      else {
        // Slide out first
        Animated.timing(this.state.slideInValue, {
          toValue: 0,
          duration: 400,
          useNativeDriver: true,
        }).start(_result => {
          // After animation is done, disable rendering
          this.setState({ renderChildren: false });
        });
      }
    }
  }

  componentDidMount() {
    if (!this.state.visible && this.props.isVisible) {
      this.setState({ visible: true });
    }
    if (this.state.visible && !this.props.isVisible) {
      this.setState({ visible: false });
    }
  }

  render() {
    const themeContext = this.context;
    const {
      children,
      insets = {},
      zIndex,
      hideCloseButton = false,
      hideDefaultBackground = false,
      onBackButtonPress = () => {
        return false;
      },
      style = {},
      modalMainBodyStyle,
      modalBodyStyle,
      dialogClassName,
      hideOverlay = false,
      customBackground,
      themeStyle = {},
      ...rest
    } = this.props;

    // Skip rendering if we shouldn't render children
    if (!this.state.renderChildren) return null;

    const deviceHeight = window.innerHeight;

    return (
      <>
        <JSModalWrapper
          style={{
            position: 'absolute',
            top: insets.top || 0,
            left: insets.left || 0,
            right: insets.right || 0,
            bottom: insets.bottom || 0,
            zIndex: zIndex || 1,
            backgroundColor: style.backgroundColor || hideOverlay ? 'none' : theme.palette.common[24],
            transform: [
              {
                translateY: this.state.slideInValue.interpolate({
                  inputRange: [0, 1],
                  outputRange: [deviceHeight, 0],
                }),
              },
            ],
            ...style,
          }}
          {...rest}
          className={`JSModal ${dialogClassName}`}
          theme={themeStyle}
        >
          <div className="modal-content" style={modalMainBodyStyle}>
            {!hideDefaultBackground && (
              <div className={'ModalBackground'}>
                {customBackground ? (
                  <ImageBW
                    className="ModalBackgroundAsset"
                    as={Animated.div}
                    source={require(asset`Modal_Backing_9Slice@3x.png`)}
                    ibw={require(asset`Modal_Backing_9Slice.ibw`)}
                    widthScale={themeContext?.JSModal?.ModalBackgroundAsset?.widthScale || '0.3'}
                  />
                ) : (
                  <ImageBW
                    className="ModalBackgroundAsset"
                    as={Animated.div}
                    source={require(asset`Dialog.png`)}
                    ibw={require(asset`Dialog.ibw`)}
                  />
                )}
              </div>
            )}
            {!hideCloseButton && (
              <div className="ModalCloseButton" onClick={onBackButtonPress}>
                <ImageBW
                  source={require(asset`CloseButton@3x.png`)}
                  ibw={require(asset`CloseButton.ibw`)}
                  manualAssetScale={3}
                  style={{
                    objectFit: 'contain',
                    height: '100%',
                    width: '100%',
                  }}
                  innerStyle={{
                    cursor: 'pointer',
                  }}
                  alt="Close"
                />
              </div>
            )}
            <div className="modal-body" style={modalBodyStyle}>
              {children}
            </div>
          </div>
        </JSModalWrapper>
      </>
    );
  }
}

JSModal.contextType = ThemeContext;

const JSModalWrapper = styled(Animated.div)`
  @media only screen and (max-width: 1300px) {
    ${({ theme }) => theme};
  }
`;

