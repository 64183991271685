import React, { Component } from 'react';
import styled from 'styled-components';
import STier from 'shared/components/Tier';
import ImageBW from '../ImageBoundsWrapper';
import Tooltip from '../Tooltip';
import { TooltipTypes } from 'shared/utils/tooltipConstants';
import Utils from 'shared/utils/index';
import ThemeContext from 'shared/context/ThemeContext';
import Button from '../Button/Button';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import generateRender from 'shared/utils/generateRender';

const CARDS = {
  Bronze: require(asset`CARD_BRONZE@3x.png`),
  Diamond: require(asset`CARD_DIAMOND@3x.png`),
  Gold: require(asset`CARD_GOLD@3x.png`),
  Platinum: require(asset`CARD_PLATINUM@3x.png`),
  Silver: require(asset`CARD_SILVER@3x.png`),
  Premier: require(asset`CARD_PREMIER@3x.png`),
  Elite: require(asset`CARD_ELITE@3x.png`),
  XCARD: require(asset`CARD_XCARD@3x.png`),
  Unlinked: require(asset`TSC_Cards@3x.png`),
};
export default class Tier extends Component {
  _hoverHandler = changeTooltipType => {
    if (this.props.tooltipType !== TooltipTypes.TIER) {
      changeTooltipType();
    }
  };

  _closeTooltip = changeTooltipType => {
    changeTooltipType();
  };

  render() {
    const themeContext = this.context;

    const tooltipButtonImageSource = generateRender({
      millelacs: require(asset`RoundedRect_Primary@2x.png`),
      default: require(asset`RoundedRect_Secondary@2x.png`),
    });

    return (
      <STier
        navigation={this.props.navigation}
        {...this.props}
        render={({
          memberLevel,
          isAccountLinked,
          tooltipTitle,
          tooltipButtonLabel,
          clickHandler,
          tooltipClickHandler,
          starDataUnavailable,
          changeTooltipType,
          digitallyVerified,
          assetsUrls,
          linkCardAssets,
          tierInfoTooltipFeature,
        }) => {
          const { showActiveTracker } = this.props;

          let asset;
          if (isAccountLinked !== 'yes' || starDataUnavailable) {
            asset = linkCardAssets ? assetsUrls.ImagesCdnBaseUrl + '/' + linkCardAssets : CARDS.Unlinked;
          } else {
            if (linkCardAssets) {
              asset = assetsUrls.ImagesCdnBaseUrl + '/' + linkCardAssets;
            } else {
              asset = digitallyVerified ? require(asset`images/Top_Bar_Assets/VIP_Ticket@3x.png`) : CARDS[memberLevel];
            }
          }

          return (
            <TierWrapper
              className="Tier"
              theme={themeContext.Tier.TierWrapper}
              showActiveTracker={showActiveTracker}
              onMouseEnter={() => this._hoverHandler(changeTooltipType)}
              onClick={clickHandler}
            >
              {asset && (
                <ImageBW
                  className="TierIcon"
                  source={asset}
                  ibw={require(asset`Tier_Icon_Web.ibw`)}
                  style={{ ...themeContext.Tier.Card, opacity: starDataUnavailable ? 0.3 : 1 }}
                  innerStyle={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
              )}
              {tierInfoTooltipFeature && tooltipTitle && (
                <div>
                  <Tooltip
                    style={{
                      marginLeft: showActiveTracker ? '1vmin' : '-9%',
                      ...styles.tooltipContainer,
                    }}
                    arrowStyle={styles.tooltipArrowStyle}
                    visible={this.props.tooltipType && this.props.tooltipType === TooltipTypes.TIER}
                  >
                    <div className="TierCardTooltip">
                      <div className="TierCardTooltipTitle">{tooltipTitle}</div>
                      {tooltipButtonLabel && (
                        <Button
                          imageSource={tooltipButtonImageSource}
                          imageStyle={styles.tooltipButtonImage}
                          containerStyle={styles.tooltipButton}
                          textStyle={styles.tooltipButtonText}
                          label={tooltipButtonLabel}
                          onClick={() => {
                            tooltipClickHandler();
                            this._closeTooltip(changeTooltipType);
                          }}
                          textStroke={BUTTON_STROKES.SECONDARY}
                        />
                      )}
                    </div>
                  </Tooltip>
                </div>
              )}
            </TierWrapper>
          );
        }}
      />
    );
  }
}

Tier.contextType = ThemeContext;

const TierWrapper = styled.div`
  position: relative;
  padding-left: ${({ showActiveTracker }) => (showActiveTracker ? 35 : 0)}px;
  margin-right: 103px;
  height: 90%;
  margin-top: ${Utils.ieVersion() !== 0 ? 10 : 0}px;
  ${({ theme }) => theme}
`;

const styles = {
  tooltipButton: {
    position: 'relative',
    textAlign: 'center',
    cursor: 'pointer',
    pointerEvents: 'auto',
    justifyContent: 'center',
    marginTop: 5,
  },
  tooltipButtonImage: {
    width: '100%',
  },
  tooltipButtonText: {
    paddingTop: 5,
  },
  tooltipArrowStyle: {
    left: window.innerWidth < 1400 ? 72 : 65,
  },
  tooltipContainer: {
    top: '120px',
    left: window.innerWidth < 1400 ? -40 : 0,
  },
};

