/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import theme from 'shared/assets/style/theme';
import ImageBW from '../../components/ImageBoundsWrapper';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { purchaseActionTriggers } from 'shared/constants/userActions';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import Button from '../../components/Button/Button';
import generateRender from 'shared/utils/generateRender';

const PRIMARY_BUTTON = require(asset`RoundedRect_Primary@3x.png`);
const SECONDARY_BUTTON = require(asset`RoundedRect_Secondary@3x.png`);
const ORANGE_BUTTON = require(asset`RoundedRect_Tertiary@3x.png`);
const GREY_BUTTON = require(asset`RoundedRect_Disabled@3x.png`);
const GREEN_BUTTON = generateRender({
  hri: PRIMARY_BUTTON,
  admiral: PRIMARY_BUTTON,
  admes: PRIMARY_BUTTON,
  default: SECONDARY_BUTTON,
  millelacs: PRIMARY_BUTTON,
});

/** @typedef {import('shared/api/Constants').BoostPackageItem} BP */

/** @param {BP} item */
export default function makeVIPItem(item, index, buyHandle, freeHandle, hasVIPPass, unlimitedVipAccess, themeContext) {
  const headerImg = [
    require(asset`images/Coin_Store_Assets/VIP_Tiles/VIP_Hour_Tile_1@3x.png`),
    require(asset`images/Coin_Store_Assets/VIP_Tiles/VIP_Hour_Tile_2@3x.png`),
    require(asset`images/Coin_Store_Assets/VIP_Tiles/VIP_Hour_Tile_3@3x.png`),
  ];

  const logos = [
    require(asset`images/Coin_Store_Assets/VIP_Tiles/VIP_Hour_Tile_1star@3x.png`),
    require(asset`images/Coin_Store_Assets/VIP_Tiles/VIP_Hour_Tile_2stars@3x.png`),
    require(asset`images/Coin_Store_Assets/VIP_Tiles/VIP_Hour_Tile_3stars@3x.png`),
  ];

  const freeImg = require(asset`images/Coin_Store_Assets/VIP_Tiles/VIP_Hour_Tile_FREE@3x.png`);

  const actionButton = unlimitedVipAccess ? GREY_BUTTON : item.is_free ? ORANGE_BUTTON : GREEN_BUTTON;
  const buttonTextStroke = unlimitedVipAccess
    ? BUTTON_STROKES.DISABLED
    : item.is_free
    ? BUTTON_STROKES.TERTIARY
    : BUTTON_STROKES.GREEN;

  return (
    <ImageBW
      source={item.is_free ? freeImg : headerImg[index]}
      ibw={require(asset`images/Coin_Store_Assets/VIP_Tiles/VIP_Hour_Tile.ibw`)}
      manualAssetScale={3}
      key={'vip-' + item.item_id}
      style={themeContext.VIPItem.VIPHourTile}
      className="vipHourTile"
      innerStyle={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common[1],
      }}
    >
      <img src={logos[index]} style={themeContext.VIPItem.LogoImage} />
      <div style={themeContext.VIPItem.DurationFriendlyFull}>{item.duration_friendly_full}</div>
      <div style={{ fontSize: '2vmin' }}>{`${getText(TEXT_KEY.VIP)} ${getText(TEXT_KEY.ACCESS)}`}</div>
      {!item.is_free && (
        <>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              height: '26%',
              width: '100%',
            }}
          >
            <div style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>{getText(TEXT_KEY.PLUS_SYMBOL)}</div>
            <ImageBW
              source={require(asset`loyalty-icon.png`)}
              ibw={require(asset`loyalty-icon.ibw`)}
              style={{ height: 30, width: 30, objectFit: 'contain', margin: '0 1em' }}
            />
            <div style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>{item.bonus_loyalty_points || '0'}</div>
          </div>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 500,
            }}
          >
            <div style={{ fontSize: '1.3rem' }}>{`${getText(TEXT_KEY.CURRENCY_SIGN)}${item.price}`}</div>
          </div>
        </>
      )}
      {item.is_free && (
        <>
          <div
            style={{
              position: 'relative',
              marginTop: '1vmin',
              flex: 1,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{ fontSize: '6vmin', fontWeight: 'bold' }}>{getText(TEXT_KEY.FREE)}</div>
            </div>
          </div>
        </>
      )}
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <Button
          imageSource={actionButton}
          imageStyle={{ width: '70%', height: '4.5rem', cursor: 'pointer' }}
          containerStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'auto',
            height: '4rem',
          }}
          objectFit="contain"
          textStyle={{ fontSize: '28px' }}
          textStroke={buttonTextStroke}
          label={item.is_free ? getText(TEXT_KEY.GET) : getText(TEXT_KEY.BUY)}
          onClick={() =>
            unlimitedVipAccess
              ? hasVIPPass()
              : item.is_free
              ? freeHandle(item.item_id, 'vip_room_access')
              : buyHandle(item.item_id, item.name, 'vip_room_access', item.price, purchaseActionTriggers.STORE_VIP_ITEM)
          }
        />
      </div>
    </ImageBW>
  );
}

