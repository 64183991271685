import { createSlice } from '@reduxjs/toolkit';
var sliceName = 'LEVEL';
var initialState = {
  level: 0,
  max: 0,
  maxBet: 0,
  nextLevelMaxBet: 0,
  previousLevelBonusCoins: 0,
  currentLevelBonusCoins: 0,
  nextLevelBonusCoins: 0,
  nextBoostLevel: 0,
  nextBoostPercent: 0
};
var levelInfoSlice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    setLevel: function setLevel(state, action) {
      state.level = action.payload;
    },
    setMaxLevel: function setMaxLevel(state, action) {
      state.max = action.payload;
    },
    setNextBoostLevel: function setNextBoostLevel(state, action) {
      state.nextBoostLevel = action.payload;
    },
    setMaxBet: function setMaxBet(state, action) {
      state.maxBet = parseInt(action.payload);
    },
    setPreviousLevelBonusCoins: function setPreviousLevelBonusCoins(state, action) {
      state.previousLevelBonusCoins = action.payload;
    },
    setCurrentLevelBonusCoins: function setCurrentLevelBonusCoins(state, action) {
      state.currentLevelBonusCoins = action.payload;
    },
    setNextLevelBonusCoins: function setNextLevelBonusCoins(state, action) {
      state.nextLevelBonusCoins = action.payload;
    },
    setNextLevelMaxBet: function setNextLevelMaxBet(state, action) {
      state.nextLevelMaxBet = action.payload;
    },
    setNextBoostPercent: function setNextBoostPercent(state, action) {
      state.nextBoostPercent = action.payload;
    }
  }
});
var actions = levelInfoSlice.actions,
  reducer = levelInfoSlice.reducer;
var setLevel = actions.setLevel,
  setMaxLevel = actions.setMaxLevel,
  setMaxBet = actions.setMaxBet,
  setPreviousLevelBonusCoins = actions.setPreviousLevelBonusCoins,
  setCurrentLevelBonusCoins = actions.setCurrentLevelBonusCoins,
  setNextLevelBonusCoins = actions.setNextLevelBonusCoins,
  setNextLevelMaxBet = actions.setNextLevelMaxBet,
  setNextBoostLevel = actions.setNextBoostLevel,
  setNextBoostPercent = actions.setNextBoostPercent;
export { setLevel, setMaxLevel, setMaxBet, setPreviousLevelBonusCoins, setCurrentLevelBonusCoins, setNextLevelBonusCoins, setNextLevelMaxBet, setNextBoostLevel, setNextBoostPercent };
export default reducer;